import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import Content from '../../layout/content/Content';
import Head from '../../layout/head/Head';
import FilterSection from '../../components/table/FilterSection';
import TableComponent from '../../components/table/TableComponent';
import { startCase } from 'lodash';
import { getColor } from '../../utils/getColor';
import { fetchAllPayments, fetchDebouncedAllPayments } from '../../redux/features/usersSlice/paymentSlice';
import { clearItems } from '../../redux/features/itemsSlice/itemsSlice';
import Invoice from './invoice';
import { Box, Chip, Stack } from '@mui/material';

const Payments = () => {
  const dispatch = useDispatch();
  
  // States
  const [filters, setFilters] = useState({
    searchText: '',
    dateRange: [null, null],
    status: '',
    vertical: '',
    currentPage: 1,
    sortByField: 'createdAt',
    sortByValue: -1
  });

  // Selectors
  const { data, totalCount, loading, perPage } = useSelector((state) => state.payments);
  const user = useSelector((state) => state.userDetails.data);
  const verticals = useSelector((state) => state.verticals);
  
  // Filter Configuration
  const filterConfig = [
    {
      key: 'searchText',
      type: 'search',
      label: 'Search',
      placeholder: 'Search ID',
      defaultValue: ''
    },
    {
      key: 'dateRange',
      type: 'dateRange',
      label: 'Date Range',
      defaultValue: [null, null]
    },

  ];

  const statusOptions = [
    { value: "", label: "All" },
    { value: "pending", label: "Pending" },
    { value: "completed", label: "Completed" },
    { value: "failed", label: "Failed" },
    { value: "cancelled", label: "Cancelled" }
  ];

  // Fetch data based on filters
  const fetchData = useCallback(() => {
    const requestData = {
      query: {},
      options: {
        page: filters.currentPage,
        limit: perPage,
        sort: filters.sortByValue === 1 ? filters.sortByField : `-${filters.sortByField}`,
        populate: [
          { path: 'user_id', select: 'full_name' },
          { path: 'item_id', select: 'title' },
          { path: 'auction_id', select: '_id' },
          { path: 'vertical_id', select: 'name' }
        ]
      }
    };

    if (filters.vertical) {
      requestData.query.vertical_id = filters.vertical.id;
    }

    if (user?.id) {
      requestData.query.user = user?.id;
    }

    if (filters.status) {
      requestData.query.status = filters.status;
    }

    if (filters.dateRange[0] && filters.dateRange[1]) {
      requestData.query.date = {
        startDate: filters.dateRange[0].toISOString(),
        endDate: filters.dateRange[1].toISOString()
      };
    }

    if (filters.searchText.trim()) {
      requestData.query.searchId = filters.searchText.trim();
      dispatch(fetchDebouncedAllPayments(requestData));
    } else {
      dispatch(fetchAllPayments(requestData));
    }
  }, [filters, dispatch, user?.id, perPage]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    return () => {
      dispatch(clearItems());
    };
  }, [dispatch]);

  // Handlers
  const handleFilterChange = (key, value) => {
    setFilters(prev => ({
      ...prev,
      [key]: value,
      currentPage: key !== 'currentPage' ? 1 : value
    }));
  };

  const handlePageChange = (newPage) =>
    handleFilterChange('currentPage', newPage);

  const handleSortChange = (field, direction) => {
    handleFilterChange('sortByField', field);
    handleFilterChange('sortByValue', direction);
  };

  const clearFilters = () => {
    setFilters({
      searchText: '',
      dateRange: [null, null],
      status: '',
      vertical: '',
      currentPage: 1,
      sortByField: 'createdAt',
      sortByValue: -1
    });
    localStorage.removeItem('verticalItemGetValue');
  };

  

  // Table columns configuration
  const columns = [
    {
      label: 'ID',
      field: 'id',
      sortable: false,
      render: (item) => <Invoice item={item} />
    },
    {
      label: 'User',
      field: 'user_id',
      sortable: false,
      render: (item) => (
        <Link to={`/users/${item?.user_id?.id}/`}>
          {startCase(item?.user_id?.full_name)}
        </Link>
      )
    },
    {
      label: 'Payment For',
      field: 'payment_for',
      sortable: false,
      render: (item) => item?.payment_for
    },
    {
      label: 'Type',
      field: 'payment_type',
      sortable: false,
      render: (item) => item?.payment_type
    },
    {
      label: 'Auction',
      field: 'auction_id',
      sortable: false,
      render: (item) => (
        <Link 
          to={`/v/${item?.vertical_id}/auctions/${item?.auction_id?.id}`}
          className="text-truncate d-inline-block"
          style={{ maxWidth: '30ch' }}
        >
          {startCase(item?.item_id?.title)}
        </Link>
      )
    },
    {
      label: 'Amount',
      field: 'amount',
      sortable: true,
      render: (item) => item?.amount?.toFixed(2)
    },
    {
      label: 'Status',
      field: 'status',
      sortable: true,
      render: (item) => (
        <Badge color={getColor(item?.status)}>
          {startCase(item?.status)}
        </Badge>
      )
    },
    {
      label: 'Created At',
      field: 'createdAt',
      sortable: true,
      render: (item) => {
        const dateOptions = {
          weekday: 'short',
          month: 'short',
          day: 'numeric',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        };
        return new Date(item?.createdAt).toLocaleString(undefined, dateOptions);
      }
    }
  ];

  return (
    <>
      <Head title="Payments" />
      <Content>
        <Box sx={{ mb: 3 }}>
          <FilterSection
            title="All Payments"
            filters={filters}
            filterConfig={filterConfig}
            statusOptions={statusOptions}
            onFilterChange={handleFilterChange}
            clearFilters={clearFilters}
          
          />

          {/* Active Vertical Filter Badge */}
          {filters.vertical && (
            <Stack direction="row" justifyContent="flex-end" sx={{ mt: 2 }}>
              <Chip
                label={filters.vertical.name}
                onDelete={() => {
                  handleFilterChange('vertical', '');
                  localStorage.removeItem('verticalItemGetValue');
                }}
                color="primary"
              />
            </Stack>
          )}
        </Box>

        <TableComponent
          data={data?.data || []}
          columns={columns}
          totalCount={totalCount}
          perPage={perPage}
          currentPage={filters.currentPage}
          onPageChange={handlePageChange}
          onSortChange={handleSortChange}
          loading={loading}
          selectable={false}
        />
      </Content>
    </>
  );
};

export default Payments;