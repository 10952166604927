import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { axios } from "../../../utils/axios";

const initialState = {
    data: [],
    totalCount : 0,
    perPage: 15,
    loading: false,
    currentPage: 1,
    error: null,
}
const fetchAllReservations = createAsyncThunk('listReservations',(data) => {
    const url = "/admin/paymenttransaction/reservations/";
       return axios.post(url,data).then((response) => {
        console.log("Reservations")
        return response.data;
    });
});

const getReservationSlice = createSlice({
    name : "listReservations",
    initialState,
    reducers: {
          resetStatus: (state) => {
          state.data = [];
          state.error = null;
          state.loading = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllReservations.pending, (state) => {
            state.loading = true;
            state.error = null;
            state.data = [];
        });

        builder.addCase(fetchAllReservations.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
            state.currentPage = action.payload?.data?.paginator?.currentPage;
            state.totalCount = action.payload?.data?.paginator?.itemCount;
            state.perPage = action.payload?.data?.paginator?.perPage;
        });

        builder.addCase(fetchAllReservations.rejected, (state,action) => {
              state.loading = false;
              state.data = {};
              state.error = action.error.message;
        });
    }
});

export default getReservationSlice.reducer;
export const { getReservation } = getReservationSlice.actions;
export { fetchAllReservations};