// components/filter-section/FilterSection.js
import React, { memo } from 'react';
import {
    Box,
    Typography,
    TextField,
    Button,
    Grid2,
    Chip,
    Tabs,
    Tab,
    styled,
    Stack,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Search, Clear, FilterList } from '@mui/icons-material';
import DateRangePickerCustom from '../date-filter/DateRangePicker';

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),
        color: theme.palette.primary.main,
        backgroundColor: '#fff',
        '&.Mui-selected': {
            color: '#fff',
            backgroundColor: theme.palette.primary.main
        },
        '&.Mui-focusVisible': {
            backgroundColor: theme.palette.action.focus,
        },
    }),
);

// Memoize custom components to prevent unnecessary re-renders
const MemoizedCustomComponent = memo(({ Component, value, onChange }) => {
    return <Component value={value} onChange={onChange} />;
});

const FilterSection = ({
    title = "Filters",
    filters = {},
    filterConfig = [],
    statusOptions = [],
    onFilterChange,
    clearFilters,
    customComponents = {},
    actions
}) => {
    // Generic handler for all filter changes
    const handleFilterChange = (filterKey, value) => {
        onFilterChange?.(filterKey, value);
    };

    // Get display value for active filters
    const getFilterDisplayValue = (config, value) => {
        if (!value) return '';
        if (config.type === 'dateRange') {
            return `${value[0] ? value[0].format("MMM Do YY") : ''} - ${value[1] ? value[1].format("MMM Do YY") : ''}`;
        }
        if (config.type === 'select' && config.options) {
            const option = config.options.find(opt => opt.value === value);
            return option ? option.label : value;
        }
        
        if (typeof value === 'object' && value !== null) {
            return value.name || value.label || JSON.stringify(value);
        }
        return value;
    };

    // Check if filter has value
    const hasFilterValue = (config, value) => {
        if (!value) return false;
        if (config.type === 'dateRange') {
            return value[0] || value[1];
        }
        if (Array.isArray(value)) {
            return value.length > 0;
        }
        if (typeof value === 'object' && value.label) {
            return value.label !== '' && value.label !== config.defaultValue;
        }
        if (typeof value === 'object') {
            return Object.keys(value).length > 0;
        }
        return value !== '' && value !== config.defaultValue;
    };

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Box sx={{ mb: 3 }}>
                {/* Header Section */}
                <Grid2 container spacing={2} alignItems="center" justifyContent='space-between'>
                    <Grid2 item xs={12} md={6} lg={6} sx={{ textAlign: 'left' }}>
                        <Typography variant="h5" component="h3" gutterBottom>
                            {title}
                        </Typography>
                    </Grid2>

                    {/* Filter Controls */}
                    <Grid2 container spacing={2} alignItems="center">
                        <Grid2 item xs={12} md={6} lg={6} sx={{ textAlign: 'right' }}>
                            <Box sx={{
                                display: 'flex',
                                gap: 2,
                                flexWrap: 'wrap',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                            }}>
                                {/* Dynamic Filter Controls */}
                                {filterConfig.map(config => {
                                    if (config.type === 'search') {
                                        return (
                                            <TextField
                                                key={config.key}
                                                size="small"
                                                placeholder={config.placeholder}
                                                value={filters[config.key] || ''}
                                                onChange={(e) => handleFilterChange(config.key, e.target.value)}
                                                InputProps={{
                                                    startAdornment: <Search fontSize="small" sx={{ color: 'text.secondary', mr: 1 }} />,
                                                }}
                                                sx={{ flexGrow: 1, maxWidth: 200 }}
                                            />
                                        );
                                    }
                                    if (config.type === 'dateRange') {
                                        return (
                                            <DateRangePickerCustom
                                                key={config.key}
                                                filters={{ dateRange: filters[config.key] }}
                                                handleDateRangeChange={(dates) => handleFilterChange(config.key, dates)}
                                            />
                                        );
                                    }
                                    if (config.type === 'custom' && customComponents?.[config.key]) {
                                        return (
                                            <MemoizedCustomComponent
                                                key={config.key}
                                                Component={customComponents[config.key]}
                                                value={filters[config.key]}
                                                onChange={(value) => {
                                                    console.log(value)
                                                    return handleFilterChange(config.key, value);
                                                }}
                                            />
                                        );
                                    }
                                    return null;
                                })}

                                {/* Action Buttons */}
                                {actions}

                                <Button
                                    variant="outlined"
                                    size='small'
                                    startIcon={<Clear />}
                                    onClick={clearFilters}
                                    sx={{ whiteSpace: 'nowrap' }}
                                >
                                    Clear Filters
                                </Button>
                            </Box>
                        </Grid2>
                    </Grid2>
                </Grid2>

                {/* Active Filters */}
                <Stack direction='row' justifyContent='space-between' marginTop={2}>
                    <Box sx={{ mt: 2, display: 'flex', gap: 1, alignItems: 'center', flexWrap: 'wrap' }}>
                        {filterConfig.some(config => hasFilterValue(config, filters[config.key])) && (
                            <>
                                <FilterList fontSize="small" sx={{ color: 'text.secondary' }} />
                                <Typography variant="body2" sx={{ mr: 1 }}>Active filters:</Typography>
                                
                                {filterConfig.map(config => (
                                    hasFilterValue(config, filters[config.key]) && (
                                        <Chip
                                            key={config.key}
                                            label={`${config.label}: ${getFilterDisplayValue(config, filters[config.key])}`}
                                            onDelete={() => handleFilterChange(config.key, config.defaultValue)}
                                            size="small"
                                        />
                                    )
                                ))}
                            </>
                        )}
                    </Box>
                </Stack>

                {/* Status Tabs */}
                {statusOptions.length > 0 && (
                    <Box width={'100%'} sx={{ marginTop: 2 }}>
                        <Tabs
                            value={filters.status || ''}
                            onChange={(_, value) => handleFilterChange('status', value)}
                            sx={{
                                borderRadius: '8px',
                                backgroundColor: 'background.paper'
                            }}
                            TabIndicatorProps={{
                                sx: { display: 'none' }
                            }}
                        >
                            {statusOptions.map((option) => (
                                <StyledTab
                                    key={option.value}
                                    value={option.value}
                                    label={option.label}
                                />
                            ))}
                        </Tabs>
                    </Box>
                )}
            </Box>
        </LocalizationProvider>
    );
};

export default FilterSection;