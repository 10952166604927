
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { axios } from "../../../utils/axios";

const initialState = {
    data: {},
    loading: false,
    error: null,
}

const refundReservationRemote = createAsyncThunk('refundReservation',(data) => {
    const url = "/admin/paymenttransaction/refund-reservation";
    return axios.post(url,{reservationId:data.reservationId}).then((response) => {
        console.log("Response: :", response);
        return response.data;
    });
});


const getRefundReservationSlice = createSlice({
    name : "refundReservation",
    initialState,
    reducers: {
          resetStatus: (state) => {
          state.data = {};
          state.error = null;
          state.loading = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(refundReservationRemote.pending, (state) => {
            state.loading = true;
            state.error = null;
            state.data = {};
        });

        builder.addCase(refundReservationRemote.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload?.data;
        });

        builder.addCase(refundReservationRemote.rejected, (state,action) => {
            console.log("Action", action);
              state.loading = false;
              state.data = {};
              state.error = action.error.message;
        });
    }
});

export default getRefundReservationSlice.reducer;
export const { refundReserve } = getRefundReservationSlice.actions;
export { refundReservationRemote};