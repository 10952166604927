import React from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper 
} from '@mui/material';

const AnalyticsTable = ({ data }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell align="right">Total Deposits</TableCell>
            <TableCell align="right">Fees</TableCell>
            <TableCell align="right">Net Balance</TableCell>
            <TableCell align="right">Active Users</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.date}>
              <TableCell>{new Date(row.date).toLocaleDateString()}</TableCell>
              <TableCell align="right">
                {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'AED' })
                  .format(row.financialStats.revenue.total.deposits ?? '0')}
              </TableCell>
              <TableCell align="right">
                {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'AED' })
                  .format(row.financialStats.revenue.total.fees ?? '0')}
              </TableCell>
              <TableCell align="right">
                {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'AED' })
                  .format(row.financialStats.revenue.total.netBalance ?? '')}
              </TableCell>
              <TableCell align="right">{row.userStats.active}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AnalyticsTable;