const menu = [
  {
    heading: 'Ravo'
  },
  {
    icon: 'dashboard-fill',
    text: 'Dashboard',
    link: '/dashboard'
  },

  {
    heading: 'Management'
  },
  {
    icon: 'users-fill',
    text: 'Users',
    subMenu: [
      {
        text: 'List Users',
        link: '/users'
      },
    ]
  },
  {
    icon: 'users-fill',
    text: 'Dealers',
    subMenu: [
      {
        text: 'List Dealers',
        link: '/dealers'
      },
      {
        text: 'Add Dealer',
        link: '/add-dealer'
      },

    ]
  },
  {
    icon: 'scan-fill',
    text: 'Auctions',
    subMenu: [
      {
        text: 'Live Auctions',
        link: '/auctions'
      },
      {
        text: 'Timed Auctions',
        link: '/nobids-auctions'
      },
    ]
  },
  {
    icon: 'list-fill',
    text: 'Inventory',
    subMenu: [
      {
        text: 'Inventory',
        link: '/items'
      }
    ]
  },
  {
    icon: 'list-fill',
    text: 'Orders',
    subMenu: [
      {
        text: 'Order List',
        link: '/orders'
      }
    ]
  },
  {
    icon: 'coin-alt-fill',
    text: 'Payments',
    subMenu: [
      {
        text: 'Payments',
        link: '/payments'
      },
      {
        text: 'Refund',
        link: '/refund'
      },
      {
        text: 'Dealer Reservation',
        link: '/reservation'
      }
    ]
  },
  {
    heading: '⚠️ Advanced'
  },
  {
    icon: "setting-fill",
    text: "Settings",
    subMenu: [
      {
        text: "App Settings",
        link: "/settings",
      },
      {
        text: "Make Settings",
        link: "/make-settings",
      },
    ],
  },
  // {
  //   icon: "tag-fill",
  //   text: "Vouchers",
  //   subMenu: [
  //     {
  //       text: "List Vouchers",
  //       link: "/vouchers",
  //     },
  //   ],
  // },
  // {
  //   icon: 'setting-fill',
  //   text: 'Free auctions on register',
  //   subMenu: [
  //     {
  //       text: 'Free auctions on register',
  //       link: '/freeAuctions'
  //     }
  //   ]
  // },
  // {
  //   icon: 'scan-fill',
  //   text: 'App Broadcast',
  //   subMenu: [
  //     {
  //       text: 'App Broadcast',
  //       link: '/pushNotification'
  //     }
  //   ]
  // },
  // {
  //   icon: 'users-fill',
  //   text: 'Vertical Settings',
  //   subMenu: [
  //     {
  //       text: 'List Verticals',
  //       link: '/verticals'
  //     }
  //     // {
  //     //   text: "Add Vertical",
  //     //   link: "/createVertical",
  //     // },
  //   ]
  // },
  {
    icon: 'signout',
    text: 'Logout',
    logout: true
  }
];
const adminMenu = [
  {
    heading: 'Ravo'
  },
  {
    icon: 'dashboard-fill',
    text: 'Dashboard',
    link: '/dashboard'
  },

  {
    heading: 'Management'
  },
  {
    icon: 'users-fill',
    text: 'Approvals',
    subMenu: [
      {
        text: 'Verify User',
        link: '/activateUsers'
      }
    ]
  },
  {
    icon: 'users-fill',
    text: 'Users',
    subMenu: [
      {
        text: 'List Users',
        link: '/users'
      },
      {
        text: 'List RM',
        link: '/staff'
      },
      {
        text: 'List Reviews',
        link: '/reviews'
      }
    ]
  },

  {
    icon: 'scan-fill',
    text: 'Auctions',
    subMenu: [
      {
        text: 'List Auctions',
        link: '/auctions'
      },
      {
        text: 'List Auctions(No Bids)',
        link: '/nobids-auctions'
      },
      {
        text: 'List Auctions(Bidder Rejected)',
        link: '/rejected-auctions'
      },
      {
        text: 'List Auctions(Seller Rejected)',
        link: '/seller-rejected-auctions'
      },
      {
        text: 'List Auctions(Confirmed)',
        link: '/confirmed-auctions'
      }
      // {
      //   text: "Concierge booking",
      //   link: "/concierge",
      // },
    ]
  },
  {
    icon: 'list-fill',
    text: 'Inventory',
    subMenu: [
      {
        text: 'Inventory',
        link: '/items'
      }
    ]
  },
  {
    icon: 'list-fill',
    text: 'Orders',
    subMenu: [
      {
        text: 'Order List',
        link: '/orders'
      }
    ]
  },
  {
    heading: 'Advanced'
  },


  {
    icon: 'signout',
    text: 'Logout',
    logout: true
  }
];
const customerSupportMenu = [
  {
    heading: 'Ravo'
  },
  {
    icon: 'dashboard-fill',
    text: 'Dashboard',
    link: '/dashboard'
  },

  {
    heading: 'Management'
  },
  {
    icon: 'users-fill',
    text: 'Approvals',
    subMenu: [
      {
        text: 'Verify User',
        link: '/activateUsers'
      }
    ]
  },
  {
    icon: 'users-fill',
    text: 'Users',
    subMenu: [
      {
        text: 'List Users',
        link: '/users'
      },
      {
        text: 'List RM',
        link: '/staff'
      },
      {
        text: 'List Reviews',
        link: '/reviews'
      }
    ]
  },

  {
    icon: 'scan-fill',
    text: 'Auctions',
    subMenu: [
      {
        text: 'List Auctions',
        link: '/auctions'
      },
      {
        text: 'List Auctions(No Bids)',
        link: '/nobids-auctions'
      },
      {
        text: 'List Auctions(Bidder Rejected)',
        link: '/rejected-auctions'
      },
      {
        text: 'List Auctions(Seller Rejected)',
        link: '/seller-rejected-auctions'
      },
      {
        text: 'List Auctions(Confirmed)',
        link: '/confirmed-auctions'
      }
      // {
      //   text: "Concierge booking",
      //   link: "/concierge",
      // },
    ]
  },
  {
    icon: 'list-fill',
    text: 'Inventory',
    subMenu: [
      {
        text: 'Inventory',
        link: '/items'
      }
    ]
  },
  {
    heading: 'Verticals'
  },
  {
    section: 'verticals'
  },
  {
    heading: 'Advanced'
  },
  // {
  //   icon: "setting-fill",
  //   text: "Services",
  //   subMenu: [
  //     {
  //       text: "Booked Services",
  //       link: "/services",
  //     },
  //     {
  //       text: "Service Providers",
  //       link: "/serviceProviders",
  //     },
  //   ],
  // },
  // {
  //   icon: "tag-fill",
  //   text: "Vouchers",
  //   subMenu: [
  //     {
  //       text: "List Vouchers",
  //       link: "/vouchers",
  //     },
  //   ],
  // },

  {
    icon: 'signout',
    text: 'Logout',
    logout: true
  }
];

export { menu, adminMenu, customerSupportMenu };
