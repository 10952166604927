// features/analytics/analyticsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axios } from '../../../utils/axios';


// Async thunks
export const fetchDailyAnalytics = createAsyncThunk(
  'analytics/fetchDaily',
  async () => {
    const response = await axios.get('/admin/analytics/daily');
    return response.data;
  }
);

export const fetchAnalyticsByRange = createAsyncThunk(
  'analytics/fetchByRange',
  async ({ startDate, endDate }) => {
    const response = await axios.get(`/admin/analytics/range?startDate=${startDate}&endDate=${endDate}`);
    return response.data;
  }
);

export const fetchAnalyticsSummary = createAsyncThunk(
  'analytics/fetchSummary',
  async ({ startDate, endDate }) => {
    const response = await axios.get(`/admin/analytics/summary?startDate=${startDate}&endDate=${endDate}`);
    return response.data;
  }
);

const initialState = {
  dailyData: null,
  rangeData: [],
  summary: null,
  loading: false,
  error: null
};

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    clearAnalytics: (state) => {
      state.dailyData = null;
      state.rangeData = [];
      state.summary = null;
    }
  },
  extraReducers: (builder) => {
    builder
      // Daily Analytics
      .addCase(fetchDailyAnalytics.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDailyAnalytics.fulfilled, (state, action) => {
        state.loading = false;
        state.dailyData = action.payload.data;
        state.error = null;
      })
      .addCase(fetchDailyAnalytics.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Range Analytics
      .addCase(fetchAnalyticsByRange.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAnalyticsByRange.fulfilled, (state, action) => {
        state.loading = false;
        state.rangeData = action.payload.data;
        state.error = null;
      })
      .addCase(fetchAnalyticsByRange.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Summary Analytics
      .addCase(fetchAnalyticsSummary.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAnalyticsSummary.fulfilled, (state, action) => {
        state.loading = false;
        state.summary = action.payload.data;
        state.error = null;
      })
      .addCase(fetchAnalyticsSummary.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export const { clearAnalytics } = analyticsSlice.actions;
export default analyticsSlice.reducer;