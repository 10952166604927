import React, { useEffect, useState } from 'react';
import Content from '../../layout/content/Content';
import {
  Badge,
  Col,
  Row,
  Button,
  Spinner
} from 'reactstrap';
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Carousal,
  PaginationComponent,
  PreviewCard,
  ReactDataTable
} from '../../components/Component';
import 'react-clock/dist/Clock.css';
import Slider from 'react-slick';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  fetchAuctionDetails,
  fetchBids
} from '../../redux/features/auctionSlice/auctionDetailsSlice';
import { Paper, Stack, Box, Grid2 } from '@mui/material';
import { dataTableColumns2, sliderSettings, watchersData } from './constants';
import LiveAuctionTimerWithScale from './components/LiveAuctionTimerWithScale';
import ForceCancelButtonWithModal from './components/ForceCancelButtonWithModal';
import RepushButtonWithModal from './components/RepushButtonWithModal';
import EditButtonWithModal from './components/EditButtonWithModal';
import BackButton from '../../components/button/BackButton';

import { startCase } from 'lodash';
import { auctionStatus } from '../../utils/Utils';
import CarDetailsList from '../cars/components/CarDetailsList';
import { fetchAuctions } from '../../redux/features/auctionSlice/auctionSlice';
import AuctionCard from './components/AuctionCard';
import { initializeApp } from 'firebase/app';
import firebaseConfig from '../../firebaseConfig.json';
import {
  getFirestore,
  collection,
  doc,
  onSnapshot
} from 'firebase/firestore';
import AuctionHistoryPage from './components/AuctionHistoryPage';
import { db } from '../../utils/firebase';
import SelectWinnerWithModal from './components/SelectWinnerWithModal';

function AuctionDetails() {


  const auctionDetails = useSelector(
    (state) => state.auctionDetails.details,
  );
  const { bids, bidsCount, loading } = useSelector(
    (state) => state.auctionDetails
  );


  const [carDetails, setCarDetails] = useState({});
  const [relatedCarsdata, setRelatedCars] = useState(null);
  const [images, setImages] = useState(null);
  const { id } = useParams();
  const [auctionStats, setAuctionStats] = useState('');
  const [liveBids, setLiveBids] = useState(bids);

  const [currentPage, setCurrentPage] = useState(1);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);


  const dispatch = useDispatch();
  useEffect(() => {
    // Fetch auction details initially
    dispatch(fetchAuctionDetails(id));
    dispatch(
      fetchBids({
        query: { auction_id: id},
        options: {
          page: currentPage,
          limit: 5,
          populate: [
            {
              path: 'user_id'
            }
          ]
        }
      })
    );

    const myCollectionRef = collection(db, 'verticals');

  }, [dispatch, id, currentPage]);





  const relatedAuctionsData = useSelector((state) => state.auctions);
  useEffect(() => {
    if (relatedAuctionsData?.data?.data) {
      setRelatedCars(
        relatedAuctionsData?.data?.data?.filter((d) => d._id !== id)
      );
    }
  }, [relatedAuctionsData, id]);

  useEffect(() => {
    if (auctionDetails?.item) {
      setCarDetails(auctionDetails?.item);
      let data = {
        query: { search: auctionDetails?.item?.title },
        options: {
          page: 1,
          limit: 10,
          sort: 'status'
        }
      };
      dispatch(fetchAuctions(data));
    }

    if (auctionDetails?.item) {
    
      setImages(auctionDetails?.item?.images);
    }
    if (auctionDetails?.vertical_id) {
      const docRef = doc(
        db,
        'verticals',
        auctionDetails?.vertical_id,
        'auctions',
        id
      );
      // Listen to changes in the Firestore document
      const unsubscribe = onSnapshot(docRef, (snapshot) => {
        if (snapshot.exists()) {
          // Extract the data from the document
          const docData = snapshot.data();
          // Check if a specific event like "BID_ADDED" occurred
          if (docData.event_type === 'BID_ADDED') {
            // Handle the "BID_ADDED" event
            // console.log('A new bid was added:', docData);
            dispatch(
              fetchBids({
                query: { auction_id: id },
                options: {
                  page: currentPage,
                  limit: 5,
                  populate: [
                    {
                      path: 'user_id'
                    }
                  ]
                }
              })
            );
          }
        } else {
          // Handle the case where the document doesn't exist
          console.log('Document does not exist.');
        }
      });
      // Clean up the listener when the component unmounts
      return () => {
        unsubscribe();
      };
    }
  }, [auctionDetails?.id]);

  const navigate = useNavigate();
  function navigateToCarDetails() {
    navigate(`/items/${carDetails?._id}`, {
      replace: true
    });
  }

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <React.Fragment>
      <Content>
        <BackButton />
        <Grid2 container spacing={4}>
          <Grid2 item size={12}>
            <Paper
              className="border-top border-2 border-primary"
              color="white"
              elevation={0}
              sx={{ p: 4 }}
            >
              <Box width="100%">
                <Stack direction="row">
                  <Box width="100%">
                    <div className="me-3">
                      <Button color="light" onClick={navigateToCarDetails}>
                        Item Details
                      </Button>
                    </div>
                  </Box>
                  <Stack direction="row" justifyContent="flex-end" width={1}>

                    {auctionDetails?.status === auctionStatus.UPCOMING && (
                      <EditButtonWithModal auction={auctionDetails} />
                    )}
                    {auctionDetails?.status === auctionStatus.LIVE && (
                      <ForceCancelButtonWithModal />
                    )}
                    {auctionDetails?.status === auctionStatus.COMPLETED && (
                      <RepushButtonWithModal
                        id={id}
                        vertical_id={auctionDetails?.vertical_id}
                        baseform_id={auctionDetails?.baseform_id}
                        emirate={auctionDetails?.emirate}
                        auction_transport={auctionDetails?.transport}
                        auction_emirate={auctionDetails?.emirate}
                        reserve_price={auctionDetails?.reserve_price}
                        starting_bid={auctionDetails?.initial_price}
                      />
                    )}
                    {/* {auctionDetails?.status === auctionStatus.LIVE && <ExtendButtonWithModal id={id} />} */}
                  </Stack>
                </Stack>
              </Box>
            </Paper>
          </Grid2>
          <Grid2 item size={8}>
            <Stack direction="column" spacing={2}>
            <Box
              width={'100%'}
              height={'36rem'}
              overflow="clip"
              sx={{ objectPosition: 'center' }}
            >
              <Paper elevation={0} sx={{ height: '36rem' }}>
                {images?.length > 0 && (
                  <Carousal images={images} id={carDetails?.id} />
                )}
              </Paper>
            </Box>
            <Paper elevation={0} sx={{ height: 1 }}>
              <div className="card-inner">
                <div className="card-title-group mb-2">
                  <div className="card-title" style={{ width: '100%' }}>
                    <div
                      style={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        display: 'flex',
                        width: '100%'
                      }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h6 className="title">
                          {auctionStats === auctionStatus.COMPLETED
                            ? 'Bids Log'
                            : 'Live Bids'}
                        </h6>
                        <Badge pill color="light" style={{ marginLeft: 8 }}>
                          {/* {bids?.length} */}
                          {bidsCount}
                        </Badge>
                      </div>
                      {auctionDetails?.status === auctionStatus.COMPLETED &&
                        !auctionDetails?.winner ? (
                          <SelectWinnerWithModal id={id}  currentPage={currentPage} setCurrentPage={setCurrentPage} />
                      ) : (
                       
                      <></>
                      )}
                    </div>
                  </div>
                </div>

                <ul className="nk-top-products">
                  {bids?.map((item, idx) => (
                    <li
                      key={(currentPage - 1) * 5 + (idx + 1)}
                      onClick={() => {
                        navigate(`/users/${item?.user_id.id}`);
                        window.scrollTo({
                          top: 0,
                          behavior: 'smooth'
                        }); // Scroll to the top of the page
                      }}
                      onMouseOver={
                        // auctionDetails.winner?.bid_id === item.id ?
                        (ev) => {
                          ev.currentTarget.style.cursor = 'pointer';
                        }
                        // : null
                      }
                      style={{
                        backgroundColor:
                          auctionDetails.winner?.bid_id === item.id
                            ? 'aliceblue'
                            : null,
                        padding: '8px',
                        borderRadius: '6px'
                      }}
                    >
                      {auctionDetails.winner?.bid_id === item.id ? (
                        <h6 className="title">
                          Winner ({startCase(item.status)}){' '}
                          {auctionDetails.winner?.final_amount
                            ? `AED ${auctionDetails.winner?.final_amount?.toLocaleString(
                              'en-US'
                            )}`
                            : ''}
                        </h6>
                      ) : (
                        <div>
                          {startCase(item.status)}{' '}
                          {item?.reason ? `(${item?.reason})` : ''}
                        </div>
                      )}
                      <div className="item">
                        <div className="thumb bg-primary-dim p-2 rounded">
                          <span className="text-primary text-center">
                            {`${(currentPage - 1) * 5 + (idx + 1)}`.padStart(
                              2,
                              '0'
                            )}
                          </span>
                        </div>
                        <div className="info">
                          <div className="title">
                            {startCase(item?.user_id?.full_name)}
                          </div>
                          <div className="count">
                            {item?.user_id?.user_register_type}
                          </div>
                        </div>
                        <div className="total">
                          <div className="amount">
                            AED {item.amount?.toLocaleString('en-US')}
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                  {bids?.length > 0 ? (
                    <PaginationComponent
                      itemPerPage={5}
                      totalItems={bidsCount}
                      paginate={paginate}
                      currentPage={currentPage}
                    />
                  ) : loading ? (
                    <div className="text-center">
                      <Spinner color="primary" />
                    </div>
                  ) : (
                    <div className="text-center">
                      <span className="text-silent">No bids</span>
                    </div>
                  )}
                </ul>
              </div>
            </Paper>
            </Stack>
          </Grid2>
          <Grid2 item size={4}>

            <Grid2 item>
            <Paper elevation={0} sx={{ height: 1 }}>
              <div className="card-inner text-center">
                <h6 className="card-text">Auction Countdown</h6>
                {auctionDetails?.status !== auctionStatus.CANCELLED && (
                  <LiveAuctionTimerWithScale
                    startTime={auctionDetails?.start_time}
                    duration={auctionDetails?.duration}
                    setAuctionStats={setAuctionStats}
                    auctionStats={auctionDetails?.status}
                  />
                )}
                {auctionDetails?.status === auctionStatus.CANCELLED && (
                  <Badge className="badge-dim badge-lg" color="primary">
                    Auction Cancelled!
                  </Badge>
                )}
              </div>

              <div className="card-inner">
                <div className="card-title-group mb-2">
                  <div className="card-title">
                    <h6 className="title">
                      Auction ID: {auctionDetails?.serial_number}
                    </h6>
                    <span className="text-secondary">
                      {' '}
                      Starting Bid: AED{' '}
                      {auctionDetails?.item?.auction_starting_price?.toLocaleString('en-US')}
                    </span>
                    
                  </div>
                </div>
                <div className="card-title-group mb-2">
                  <div className="card-title">
                    <h6 className="title">Item Details</h6>
                  </div>
                </div>

                <ul className="nk-store-statistics">
                  <li>
                    <Row>
                      <Col sm="12">
                        <div className="info">
                          <div className="title"> Owner</div>
                          <div className="count-details">
                            <Link to={`/users/${auctionDetails?.user_id?.id}`}>
                              {`${startCase(
                                auctionDetails?.user_id?.full_name
                              )} ( ${auctionDetails?.user_id?.email} )`}
                            </Link>
                          </div>

                          {/* <div className="count-details">{`${auctionDetails?.user_id?.full_name} ( ${auctionDetails?.user_id?.email} )`}</div> */}
                        </div>
                      </Col>
                    </Row>
                  </li>
                  <CarDetailsList data={auctionDetails?.item} />
                </ul>
              </div>
            </Paper>
            </Grid2>
          </Grid2>

          <Grid2 item size={4}>
            
          </Grid2>
        </Grid2>

        <Block size="lg" className="mt-5">
          {relatedCarsdata?.length > 0 && (
            <BlockHead>
              <BlockBetween>
                <BlockHeadContent>
                  <BlockTitle>Related Auctions</BlockTitle>
                </BlockHeadContent>
              </BlockBetween>
            </BlockHead>
          )}

          {relatedCarsdata && relatedCarsdata?.length > 0 && (
            <Slider {...sliderSettings}>
              {relatedCarsdata?.map((item) => {
                return <AuctionCard key={item.id} item={item} />;
              })}
            </Slider>
          )}
        </Block>
      </Content>
    </React.Fragment>
  );
}
export default AuctionDetails;
