import React, { useEffect, useState, useCallback, memo } from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { debounce } from 'lodash';
import { axios } from '../../utils/axios';

const DealerAutoComplete = memo(({
  transformer,
  onSelect,
  value: passedValue,
  label = "Dealers",
  placeholder,
  error,
  helperText,
  required,
  disabled,
  ...props
}) => {
  // States
  const [value, setValue] = useState(null);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  // Update value when passedValue changes
  useEffect(() => {
    if (passedValue) {
      // If passedValue is an ID, find the full object from options
      if (typeof passedValue === 'string' || typeof passedValue === 'number') {
        setValue(options.find((v) => v.id === passedValue) || null);
      } else {
        // If passedValue is already an object, use it directly
        setValue(passedValue);
      }
    } else {
      setValue(null);
    }
  }, [passedValue, options]);

  // Fetch data from API
  const fetchAutocompleteOptions = useCallback(async (data) => {
    try {
      setLoading(true);
      const response = await axios.get('/admin/companies', { 
        params: data,
        // Add any necessary headers or config
      });
      const results = response.data?.['data']?.['data'] ?? [];
      return results;
    } catch (error) {
      console.error('Error fetching dealers:', error);
      return [];
    } finally {
      setLoading(false);
    }
  }, []);

  // Debounced fetch function
  const debouncedFetch = React.useMemo(
    () =>
      debounce(async (search, callback) => {
        const results = await fetchAutocompleteOptions({ search });
        callback(results);
      }, 400),
    [fetchAutocompleteOptions]
  );

  // Handle input value changes
  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    debouncedFetch(inputValue, (results) => {
      if (active) {
        let newOptions = [];
        
        if (value) {
          newOptions = [value];
        }
        if (results) {
          newOptions = [...newOptions, ...results.filter(
            (option) => value?._id !== option._id
          )];
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
      debouncedFetch.cancel();
    };
  }, [value, inputValue, debouncedFetch]);

  // Handle opening of autocomplete
  const handleOpen = useCallback(async () => {
    setOpen(true);
    if (!options.length) {
      const results = await fetchAutocompleteOptions({ search: '' });
      setOptions(results);
    }
  }, [fetchAutocompleteOptions, options.length]);

  // Handle closing of autocomplete
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  // Handle value change
  const handleChange = useCallback((event, newValue) => {
    setValue(newValue);
    onSelect?.(newValue);
  }, [options, onSelect]);

  // Handle input change
  const handleInputChange = useCallback((event, newInputValue) => {
    setInputValue(newInputValue);
  }, []);

  return (
    <div style={{ minWidth: '100px' }}>
      <Autocomplete
        sx={{ minWidth: 250 }}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        value={value}
        options={options}
        loading={loading}
        disabled={disabled}
        getOptionLabel={(option) => option?.name ?? ''}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        filterOptions={(x) => x}
        onInputChange={handleInputChange}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            placeholder={placeholder}
            size="small"
            required={required}
            error={error}
            helperText={helperText}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        {...props}
      />
    </div>
  );
});

DealerAutoComplete.displayName = 'DealerAutoComplete';

export default DealerAutoComplete;