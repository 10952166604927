import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Badge,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner
} from 'reactstrap';
import Content from '../../layout/content/Content';
import TableComponent from '../../components/table/TableComponent';
import { capitalize } from '../../utils/Utils';
import { fetchAllReservations } from '../../redux/features/usersSlice/vehiclereservationSlice';
import { settleDealerRemote } from '../../redux/features/usersSlice/settleDealerSlice';
import { refundReservationRemote } from '../../redux/features/usersSlice/refundReservationSlice';
import { toast } from 'react-toastify';
import { Chip, Stack } from '@mui/material';
import FilterSection from '../../components/table/FilterSection';

const DealerReservationsPage = () => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({
    searchText: '',
    status: '',
    dealer: '',
    dateRange: [null, null],
    currentPage: 1,
    sortByField: 'createdAt',
    sortByValue: -1
  });

  const { data, totalCount, loading, perPage } = useSelector((state) => state.reservations);
  const settleDealerSelector = useSelector((state) => state.settleDealer);
  const refundReservationSelector = useSelector((state) => state.refundReservation);

  // Fetch data based on filters
  const fetchData = useCallback(() => {
    const requestData = {
      options: {
        page: filters.currentPage,
        limit: perPage,
        sort: filters.sortByValue === 1 ? filters.sortByField : `-${filters.sortByField}`
      },
      query: {}
    };

    if (filters.status) {
      requestData.query.status = filters.status;
    }

    if (filters.dealer) {
      requestData.query.dealer = filters.dealer;
    }

    if (filters.dateRange[0] && filters.dateRange[1]) {
      requestData.query.date = {
        from: filters.dateRange[0].toISOString(),
        to: filters.dateRange[1].toISOString()
      };
    }

    if (filters.searchText.trim()) {
      requestData.query.search = filters.searchText.trim();
    }

    dispatch(fetchAllReservations(requestData));
  }, [filters, dispatch, perPage]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Handle status updates
  useEffect(() => {
    if (settleDealerSelector.error) {
      toast.error(settleDealerSelector.error);
    } else if (settleDealerSelector?.data?.fatoorahInvoiceId) {
      toast.success(`Dealer settled successfully, payment reference: ${settleDealerSelector.data.fatoorahInvoiceId}`);
      fetchData();
    }
  }, [settleDealerSelector, fetchData]);

  useEffect(() => {
    if (refundReservationSelector.error) {
      toast.error(refundReservationSelector.error);
    } else if (refundReservationSelector?.data._id) {
      toast.success('Refund initiated successfully.');
      fetchData();
    }
  }, [refundReservationSelector, fetchData]);

  // Handlers
  const handlePageChange = (newPage) =>
    setFilters((prev) => ({ ...prev, currentPage: newPage }));

  const handleSortChange = (field, direction) => {
    setFilters((prev) => ({
      ...prev,
      sortByField: field,
      sortByValue: direction,
      currentPage: 1
    }));
  };

   // Handle filter changes
   const handleFilterChange = (key, value) => {
    setFilters(prev => ({
        ...prev,
        [key]: value
    }));
};



  const getStatusBadgeColor = (status) => {
    switch (status?.toLowerCase()) {
      case "initiated": return "default";
      case "captured": return "success";
      case "refund_initiated": return "secondary";
      case "reserved":
      case "refund_success": return "primary";
      case "cancelled": return "error";
      case "settled" :
      case "fully_paid":  
        return "success";
      default: return "default";
    }
  };

  const settleDealer = (id) => {
    dispatch(settleDealerRemote({ reservationId: id }));
  };

  const initiateRefund = (id) => {
    dispatch(refundReservationRemote({ reservationId: id }));
  };

  const columns = [
    {
      label: 'ID',
      field: 'reservation_id',
      sortable: true,
      render: (item) => item.reservation_id
    },
    {
      label: 'User',
      field: 'user',
      sortable: false,
      render: (item) => (
        <div>
          <Link to={`/users/${item.user?.id}`}>{item.user?.full_name}</Link>
          <br />
          {item.user?.phone_number}
        </div>
      )
    },
    {
      label: 'Dealer',
      field: 'dealer',
      sortable: false,
      render: (item) => (
        <div>
          <Link to={`/users/${item.dealer?.id}`}>{item.dealer?.company.name}</Link>
          <br />
          {item.dealer?.phone_number}
        </div>
      )
    },
    {
      label: 'Date',
      field: 'createdAt',
      sortable: true,
      render: (item) => new Date(item.createdAt).toLocaleString()
    },
    {
      label: 'Amount',
      field: 'amount',
      sortable: true,
      render: (item) => item.amount?.toFixed(2)
    },
    {
      label: 'Order Id',
      field: 'order_id',
      sortable: false,
      render: (item) =>
       ( <div>
        <Link to={`/orders/${item.order?.id}`}>{item.order?.order_id}</Link><br/>
        <Chip size='small' color={getStatusBadgeColor(item.order?.status)} label={capitalize(item.order?.status)}/>
      </div>)
    },
    {
      label: 'Status',
      field: 'status',
      sortable: true,
      render: (item) => (
        <Chip 
          color={getStatusBadgeColor(item.status)} 
          label={capitalize(item.status)} 
        />
      )
    },
    {
      label: 'Type',
      field: 'orderFrom',
      sortable: false,
      render: (item) => capitalize(item.orderFrom)
    },
    {
      label: 'Actions',
      field: 'actions',
      sortable: false,
      render: (item) => {
        if (["settled", "refund_initiated", "refund_success"].includes(item.status) || item.amount <= 0) {
          return null;
        }
        return (
          <Stack direction="row" spacing={1}>
            <Button 
              color="secondary" 
              onClick={() => initiateRefund(item._id)}
            >
              Refund to user
            </Button>
            <Button 
              color="primary" 
              onClick={() => settleDealer(item._id)}
            >
              Settle dealer
            </Button>
          </Stack>
        );
      }
    }
  ];
  const filterConfig = [
    {
        key: 'searchText',
        type: 'search',
        label: 'Search',
        placeholder: 'Search title',
        defaultValue: ''
    },
    {
        key: 'dateRange',
        type: 'dateRange',
        label: 'Date',
        defaultValue: [null, null]
    },
    {
        key: 'customField',
        type: 'custom',
        label: 'Custom Filter',
        defaultValue: ''
    }
];

const statusOptions = [
    { value: "", label: "All" },
    { value: 'initiated', label: "Initiated" },
    { value: 'pending', label: "Pending" },
    { value: 'cancelled', label: "Cancelled" },
    { value: 'refund_success', label: "Refund Success" },
    { value: 'refund_failed', label: "Refund Failed" },
    { value: 'settled', label: "Settled" },
];

  return (
    <React.Fragment>
      <Content>
        <FilterSection
          title="Reservations"
          filters={filters}
          filterConfig={filterConfig}
          statusOptions={statusOptions}
          onFilterChange={handleFilterChange} 
        />
        <TableComponent
          data={data?.data || []}
          columns={columns}
          totalCount={totalCount}
          perPage={perPage}
          currentPage={filters.currentPage}
          onPageChange={handlePageChange}
          onSortChange={handleSortChange}
          loading={loading}
          selectable={false}
        />


      </Content>
    </React.Fragment>
  );
};

export default DealerReservationsPage;