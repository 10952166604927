import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useSearchParams, useNavigate } from 'react-router-dom';
import {
  Badge,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody
} from 'reactstrap';
import Content from '../../layout/content/Content';
import Head from '../../layout/head/Head';
import FilterSection from '../../components/table/FilterSection';
import TableComponent from '../../components/table/TableComponent';
import { 
  clearItems, 
  debouncedFetchCars, 
  fetchCars, 
  setAllSelected, 
  setSelected 
} from '../../redux/features/itemsSlice/itemsSlice';
import { fetchVerticalDetails } from '../../redux/features/verticalsSlice/verticalDetailsSlice';
import { startCase } from 'lodash';
import { getColor } from '../../utils/getColor';
import { Box, Chip, Stack } from '@mui/material';
import { Icon } from '../../components/Component';

const AllItems = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { verticalName } = useParams();
  const [searchParams] = useSearchParams();
  
  // States
  const [filters, setFilters] = useState({
    searchText: '',
    dateRange: [null, null],
    status: '',
    vertical: '',
    currentPage: 1,
    sortByField: 'createdAt',
    sortByValue: -1
  });
  const [modalOpen, setModalOpen] = useState(false);

  // Selectors
  const { data, totalCount, loading, perPage } = useSelector((state) => state.items);
  const user = useSelector((state) => state.userDetails.data);
  const verticals = useSelector((state) => state.verticals);
  const selectedVertical = useSelector((state) => state.verticalDetails?.data);

  // Effects
  useEffect(() => {
    const v_id = searchParams.get("v_id");
    if (v_id) dispatch(fetchVerticalDetails(v_id));
  }, [searchParams, dispatch]);

  // Filter Configuration
  const filterConfig = [
    {
      key: 'searchText',
      type: 'search',
      label: 'Search',
      placeholder: 'Search title',
      defaultValue: ''
    },
    {
      key: 'dateRange',
      type: 'dateRange',
      label: 'Date Range',
      defaultValue: [null, null]
    },
  ];

  // Fetch data based on filters
  const fetchData = useCallback(() => {
    const requestData = {
      query: {},
      options: {
        page: filters.currentPage,
        limit: perPage,
        sort: filters.sortByValue === 1 ? filters.sortByField : `-${filters.sortByField}`,
        populate: [
          { path: 'user_id', select: 'full_name' }
        ]
      }
    };

    if (user?.id) {
      requestData.query.user = user?.id;
    }

    if (filters.status) {
      requestData.query.status = filters.status;
    }

    if (filters.dateRange[0] && filters.dateRange[1]) {
      requestData.query.date = {
        startDate: filters.dateRange[0].toISOString(),
        endDate: filters.dateRange[1].toISOString()
      };
    }

    if (filters.searchText.trim()) {
      requestData.query.search = filters.searchText.trim();
      dispatch(debouncedFetchCars(requestData));
    } else {
      dispatch(fetchCars(requestData));
    }
  }, [filters, dispatch, user?.id, perPage]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    return () => {
      dispatch(clearItems());
    };
  }, [dispatch]);

  // Handlers
  const handleFilterChange = (key, value) => {
    setFilters(prev => ({
      ...prev,
      [key]: value,
      currentPage: key !== 'currentPage' ? 1 : value
    }));
  };

  const handlePageChange = (newPage) => 
    handleFilterChange('currentPage', newPage);

  const handleSortChange = (field, direction) => {
    handleFilterChange('sortByField', field);
    handleFilterChange('sortByValue', direction);
  };

  const clearFilters = () => {
    setFilters({
      searchText: '',
      dateRange: [null, null],
      status: '',
      currentPage: 1,
      sortByField: 'createdAt',
      sortByValue: -1
    });
    localStorage.removeItem('verticalItemGetValue');
  };


  // Table columns configuration
  const columns = [
    {
      label: 'ID',
      field: 'baseform_id',
      sortable: false,
      render: (item) => (
        <Link to={`/items/${item.id}`}>
          {item?.baseform_id}
        </Link>
      )
    },
    {
      label: 'Title',
      field: 'title',
      sortable: true,
      render: (item) => (
        <Link to={`/items/${item.id}`}>
          {`${item?.make} ${item?.model} ${item?.year}`}
        </Link>
      )
    },
    {
      label: 'User',
      field: 'user_id',
      sortable: false,
      render: (item) => startCase(item?.user_id?.full_name)
    },
    {
      label: 'Status',
      field: 'status',
      sortable: true,
      render: (item) => (
        <Badge color={getColor(item?.status)}>
          {startCase(item?.status)}
        </Badge>
      )
    },
    {
      label: 'Created At',
      field: 'createdAt',
      sortable: true,
      render: (item) => {
        const dateOptions = {
          weekday: 'short',
          month: 'short',
          day: 'numeric',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        };
        return new Date(item?.createdAt).toLocaleString(undefined, dateOptions);
      }
    },
    {
      label: 'Actions',
      field: 'actions',
      sortable: false,
      render: (item) => (
        <div className="d-flex justify-content-end g-2">
          <Link
            to={`/v/${verticalName}/items/${item.id}`}
            className="btn btn-trigger btn-icon"
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
          >
            <Icon name="eye" />
          </Link>
          <UncontrolledDropdown>
            <DropdownToggle tag="a" className="btn btn-icon dropdown-toggle btn-trigger">
              <Icon name="more-h" />
            </DropdownToggle>
            <DropdownMenu end>
              <ul className="link-list-opt no-bdr">
                <li>
                  <Link to={`/v/${verticalName}/items/${item.id}`}>
                    <Icon name="eye" />
                    <span>View Details</span>
                  </Link>
                </li>
                {item.active && (
                  <li>
                    <DropdownItem tag="a" href="#approve" onClick={(e) => e.preventDefault()}>
                      <Icon name="truck" />
                      <span>Approve</span>
                    </DropdownItem>
                  </li>
                )}
              </ul>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      )
    }
  ];

  // Custom actions
  const customActions = (
    <>
      {verticalName === 'Cars' && (
        <Button color="primary" onClick={() => setModalOpen(true)}>
          <Icon name="plus" />
          <span>Add Item</span>
        </Button>
      )}
    </>
  );

  return (
    <>
      <Head title="Inventory" />
      <Content>
        <Box sx={{ mb: 3 }}>
          <FilterSection
            title="All Inventory"
            filters={filters}
            filterConfig={filterConfig}
            onFilterChange={handleFilterChange}
            clearFilters={clearFilters}
            actions={customActions}
          />

          {/* Active Vertical Filter Badge */}
          {filters.vertical && (
            <Stack direction="row" justifyContent="flex-end" sx={{ mt: 2 }}>
              <Chip
                label={filters.vertical.name}
                onDelete={() => {
                  handleFilterChange('vertical', '');
                  localStorage.removeItem('verticalItemGetValue');
                }}
                color="primary"
              />
            </Stack>
          )}
        </Box>

        <TableComponent
          data={data || []}
          columns={columns}
          totalCount={totalCount}
          perPage={perPage}
          currentPage={filters.currentPage}
          onPageChange={handlePageChange}
          onSortChange={handleSortChange}
          loading={loading}
          selectable={true}
          onSelectAll={(checked) => dispatch(setAllSelected({ value: checked }))}
          onSelectRow={(id, checked) => dispatch(setSelected({ id, value: checked }))}
        />

        {/* Add Item Modal */}
        <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
          <ModalHeader toggle={() => setModalOpen(false)}>Add New Item</ModalHeader>
          <ModalBody>
            {/* Add your form components here */}
          </ModalBody>
        </Modal>
      </Content>
    </>
  );
};

export default AllItems;