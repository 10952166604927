import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { axios } from "../../../utils/axios";




const initialState = {
    data: [],
    totalCount : 0,
    perPage: 15,
    currentPage: 1,
    loading: false,
    error: null,
}

const fetchAllRefunds = createAsyncThunk('listRefunds',(data) => {
    const url = "/admin/paymenttransaction/refund";
       return axios.post(url,data).then((response) => {
        console.log("Refunds")
        return response.data;
    });
});
export const processRefundRequest = createAsyncThunk('processRefunds',(data) => {
    const url = `/admin/paymenttransaction/refund/${data}/process-refund`;
       return axios.post(url).then((response) => {
        return response.data;
    });
});

const getRefundSlice = createSlice({
    name : "listRefunds",
    initialState,
    reducers: {
          resetStatus: (state) => {
          state.data = [];
          state.error = null;
          state.loading = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllRefunds.pending, (state) => {
            state.loading = true;
            state.error = null;
            state.data = [];
        });

        builder.addCase(fetchAllRefunds.fulfilled, (state, action) => {
        
            state.loading = false;
            state.data = action.payload.data;
            state.totalCount = action.payload?.data?.paginator?.itemCount;
            state.perPage = action.payload?.data?.paginator?.perPage;
        });

        builder.addCase(fetchAllRefunds.rejected, (state,action) => {
              state.loading = false;
              state.data = {};
              state.error = action.error.message;
        });
    }
});

export default getRefundSlice.reducer;
export const { getRefund } = getRefundSlice.actions;
export { fetchAllRefunds};