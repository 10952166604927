import React, { useEffect } from "react";

import { useState } from "react";
import { Button, Icon, PaginationComponent } from "../../../components/Component";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { resetSelectWinner, selectAuctionWinner } from "../../../redux/features/auctionSlice/selectWinnerSlice";
import { toast } from "react-toastify";

export default function SelectWinnerWithModal({ id }) {
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginatedBids, setPaginatedBids] = useState([]);
  const [checked, setChecked] = useState("");
  const toggle = () => {
    setCurrentPage(1);
    setIsOpen(!isOpen);
  };
  const { bids } = useSelector((state) => state.auctionDetails);
  useEffect(() => {
    setPaginatedBids(bids?.slice(0, 5));
  }, [bids, isOpen]);

  const resPerPage = 5;

  const paginate = (pageNumber) => {
    const start = (pageNumber - 1) * resPerPage;
    const end = (pageNumber - 1) * resPerPage + 5;
    setPaginatedBids(bids?.slice(start, end));
    setCurrentPage(pageNumber);
  };
  const { loading, status } = useSelector((state) => state.selectWinner);
  useEffect(() => {
    if (status === "success") {
      toast.success("Selected Winner Successfully");
      dispatch(resetSelectWinner());
      setIsOpen(false);
    } else if (status === "error") {
      toast.error("Select winner failed");
      dispatch(resetSelectWinner());
    }
  }, [status]);
  const dispatch = useDispatch();
  const selectWinner = () => {
    const bid = bids[checked];
    const data = { id, body: { bid_id: bid.id, auction_id: id} };
    // console.log(data);
    dispatch(selectAuctionWinner(data));
  };

  return (
    <div>
      <div>
        <Button color="light" onClick={toggle}>
          Select Winner
        </Button>
      </div>
      <Modal isOpen={isOpen}>
        <ModalHeader
          toggle={toggle}
          close={
            <button className="close" onClick={toggle}>
              <Icon name="cross" />
            </button>
          }
        >
          Select Winner
        </ModalHeader>
        <ModalBody>
          <ul className="nk-top-products">
            {paginatedBids.map((item, idx) => (
              <li
                className={`item`}
                key={idx}
                onClick={() => setChecked(bids?.indexOf(item))}
                style={{
                  backgroundColor: checked === bids?.indexOf(item) ? "aliceblue" : "",
                  padding: "4px",
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  margin: "4px",
                  borderRadius: "4px",
                }}
              >
                <input
                  type="radio"
                  className="custom-control custom-radio custom-control-lg"
                  checked={checked === bids?.indexOf(item)}
                />
                <div style={{ width: 10 }} />
                <div className="thumb bg-primary-dim p-2 rounded">
                  <span className="text-primary text-center">0{bids?.indexOf(item) + 1}</span>
                </div>
                <div className="info">
                       <div className="title">{item.user_id?.full_name}</div>
                          <div className="count">{item.user_id?.user_register_type}</div>
                </div>
                <div className="total">
                  <div className="amount">AED {item.amount?.toLocaleString('en-US')}</div>
                </div>
              </li>
            ))}
          </ul>

          <PaginationComponent
            itemPerPage={resPerPage}
            totalItems={bids.length ?? 0}
            paginate={paginate}
            currentPage={currentPage}
          />
        </ModalBody>
        <ModalFooter className="bg-light">
          <Button color="primary" onClick={loading ? null : selectWinner}>
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
