import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Badge, Card, Col } from "reactstrap";
import { getAuctionStatus, getAuctionStatusBgColor } from "../../../utils/getColor";
import { startCase } from "lodash";
import { Image } from "../../../components/Component";

function AuctionCard({item}) {
    const navigate =useNavigate();

  return (
    <React.Fragment>
               <Col key={item.id} style={{ height: 550 }}>
                    <Card   onClick={() => {
                        navigate(`/v/${item?.vertical_id}/auctions/${item.id}`,{ replace: true });
                        window.location.reload();

                      }}
                    className="card-bordered  me-3 ms-3">
                      <div className="product-thumb">
                        <Link to={`/v/${item?.vertical_id}/auctions/${item.id}`}>                          
                          <Image className="card-img-top" image={item?.baseform?.title_image} size={250} />                            
                        </Link>
                      </div>
                      <div className="card-inner text-left">
                        <h5 className="product-title">
                          {/* <Link  to={`/v/${item?.vertical_id}/auctions/${item.id}`} replace={false}> */}
                            {startCase(item?.baseform?.make)}   {startCase(item?.baseform?.model)} {startCase(item?.baseform?.year)}                         
                            {/* </Link> */}
                        </h5>
                        <div>
                          <span>{"ID"} :  {item?.serial_number}</span>
                        </div>
                       
                        <div>
                          <span>{item.baseform?.subtitle1?.label} : {item.baseform?.subtitle1?.value}</span>
                        </div>
                        <div>
                          <span>{item.baseform?.subtitle2?.label} : {item.baseform?.subtitle2?.value}</span>
                        </div>
                        <div>
                          <span>
                            Status :{" "}
                            <Badge
                              className="badge-sm  has-bg d-none d-sm-inline-flex"
                              color={getAuctionStatusBgColor(item.status)}
                            >
                            {startCase(getAuctionStatus(item.status))}
                            </Badge>
                          </span>
                        </div>
                        <div className="product-price text-primary h5">
                          {/* <small className="text-muted del fs-13px">AED{item.initial_price}</small>  */}
                          AED {item?.initial_price?.toLocaleString('en-US')}
                        </div>
                      </div>
                    </Card>
                  </Col>
    </React.Fragment>
  );
}
export default AuctionCard;

