import React, { useEffect, useState, useCallback, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';

import Content from '../../layout/content/Content';
import TableComponent from '../../components/table/TableComponent';
import { getOrderStatusColor } from '../../utils/getColor';

import {
  fetchOrders,
  setAllSelected,
  setSelected
} from '../../redux/features/ordersSlice/ordersListSlice';
import OptionsMenu from './components/OptionsMenu';
import AddTransaction from './components/AddTransaction';
import MarkAsSold from './components/MarkAsSold';
import CancelOrder from './components/CancelOrder';
import DispatchVehicle from './components/DispatchVehicle';
import { toast } from 'react-toastify';
import UpdateUserInfo from './components/UpdateUserInfo';
import { capitalize } from '../../utils/Utils';
import DealerAutoComplete from '../../components/auto_complete/DealerAutoComplete';
import FilterSection from '../../components/table/FilterSection';

const DealerFilter = memo(({ onChange, value }) => {
  const [dealerData, setDealerData] = useState( null);

  useEffect(() => {
    if (value?.value !== dealerData?._id) {
      setDealerData(null);
    }
  }, [value]);

  return (
    <DealerAutoComplete
      onSelect={(v) => {
        setDealerData(v);
        if (v){
        onChange({ label: v.name, value: v._id });
        } else {
          onChange(null);
        }
      }}
      value={dealerData}
    />
  );
});

const OrdersListPage = () => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({
    searchText: '',
    status: '',
    dateRange: [null, null],
    currentPage: 1,
    sortByField: 'createdAt',
    sortByValue: -1,
    vertical: '',
    dealer: null
  });

  const { data, totalCount, fetchOrdersStatus, perPage, selected } =
    useSelector((state) => state.orders);
  const { loggedInUser: user } = useSelector((state) => state.auth);

  // Fetch data based on filters
  const fetchData = useCallback(() => {
    const {
      currentPage,
      sortByField,
      sortByValue,
      status,
      dateRange,
      dealer,
      searchId
    } = filters;

    const requestData = {
      query: {},
      options: {
        page: currentPage,
        limit: perPage,
        sort: sortByValue === 1 ? sortByField : `-${sortByField}`,
        populate: [{ path: 'user_id', select: 'full_name' }]
      }
    };
    if (status) {
      requestData.query.status = status;
    }
    if (dealer) {
      if (typeof dealer === 'object') {
        requestData.query.dealer = dealer.value;
      } else {
        requestData.query.dealer = dealer;
      }
    }
    if (dateRange) {
      requestData.query.date = {
        from: dateRange?.[0],
        to: dateRange?.[1]
      };
    }
    if (searchId) {
      requestData.query.searchId = searchId;
    }
    dispatch(fetchOrders(requestData));
  }, [filters, dispatch, user?.id, perPage]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Handle pagination and sorting
  const handlePageChange = (newPage) =>
    setFilters((prev) => ({ ...prev, currentPage: newPage }));

  const handleSortChange = (field, direction) => {
    setFilters((prev) => ({
      ...prev,
      sortByField: field,
      sortByValue: direction,
      currentPage: 1
    }));
  };

  const handleSelectAll = (checked) => {
    dispatch(setAllSelected({ value: checked }));
  };
  const handleSelectRow = (id, checked) => {
    dispatch(setSelected({ id, value: checked }));
  };

  const clearFilters = () => {
    setFilters({
      searchText: '',
      dateRange: [null, null],
      status: '',
      currentPage: 1,
      sortByField: 'createdAt',
      sortByValue: -1
    });
  };

  const columns = [
    {
      label: 'ID',
      field: 'order_id',
      sortable: true,
      render: (item) => <Link to={`/orders/${item.id}`}>{item?.order_id}</Link>
    },
    {
      label: 'Vehicle',
      field: 'vehicle',
      sortable: true,
      render: (item) => (
        <Link
          to={`/orders/${item.id}`}
        >{`${item?.vehicle?.make} ${item?.vehicle?.model} ${item?.vehicle?.year}`}</Link>
      )
    },
    {
      label: 'User',
      field: 'user_id',
      sortable: false,
      render: (item) => item?.user?.full_name ?? item?.guestUserDetails?.name
    },
    {
      label: 'Dealer',
      field: 'dealer_id',
      sortable: false,
      render: (item) => item?.dealer?.company?.name ?? ''
    },
    {
      label: 'Amount',
      field: 'amount',
      sortable: false,
      render: (item) => item?.amount?.toFixed(2)
    },
    {
      label: 'Paid Amount',
      field: 'paid_amount',
      sortable: false,
      render: (item) =>
        (Number(item?.amount) - Number(item?.balanceAmount))?.toFixed(2)
    },
    {
      label: 'Status',
      field: 'status',
      sortable: true,
      render: (item) => (
        <Badge color={getOrderStatusColor(item?.status)}>
          {capitalize(item?.status.toString())}
        </Badge>
      )
    },
    {
      label: 'Created At',
      field: 'createdAt',
      sortable: true,
      render: (item) => new Date(item?.createdAt).toLocaleString()
    },
    {
      label: 'Actions',
      field: 'actions',
      sortable: false,
      render: (item) => (
        <OptionsMenu order={item} handleOpenDialog={handleOpenDialog} />
      )
    }
  ];

  const [activeMenuDialog, setActiveMenuDialog] = useState(null);

  const [selectedOrder, setSelectedOrder] = useState(null);

  const handleOpenDialog = (type, order) => {
    console.log('Selected order :: ', order);
    setSelectedOrder(order);
    setActiveMenuDialog(type);
  };

  // Function to close the dialog
  const handleCloseDialog = (e) => {
    // selectedOrder = null;
    setActiveMenuDialog(null);
  };

  const menuItems = {
    UPDATE_USER: 'UPDATE_USER',
    TRANSACTION: 'TRANSACTION',
    SOLD: 'SOLD',
    CANCEL: 'CANCEL',
    DISPATCH: 'DISPATCH'
  };

  const addTransactionStatus = useSelector(
    (state) => state.addTransaction,
    (a, b) => a.addTransaction?.orderStatus !== b.addTransaction?.orderStatus
  );
  const cancelOrderStatus = useSelector(
    (state) => state.cancelOrder,
    (a, b) => a.addTransaction?.orderStatus !== b.addTransaction?.orderStatus
  );
  const dispatchVehicleStatus = useSelector(
    (state) => state.dispatchVehicle,
    (a, b) => a.dispatchVehicle?.orderStatus !== b.dispatchVehicle?.orderStatus
  );
  const markSoldStatus = useSelector(
    (state) => state.markAsSold,
    (a, b) => a.markAsSold?.orderStatus !== b.markAsSold?.orderStatus
  );
  const updateUserOrderStatus = useSelector(
    (state) => state.updateUserOrder,
    (a, b) => a.updateUserOrder?.orderStatus !== b.updateUserOrder?.orderStatus
  );

  useEffect(() => {
    console.log('Effect triggered :: ', addTransactionStatus);
    if (addTransactionStatus.orderStatus === 'success') {
      toast.success('Transaction added successfully!');
      return;
    } else if (addTransactionStatus.orderStatus === 'error') {
      toast.error(addTransactionStatus.error ?? 'Failed to add transaction!');
      return;
    }
  }, [addTransactionStatus]);

  useEffect(() => {
    console.log('Effect triggered :: ', cancelOrderStatus);
    if (cancelOrderStatus.orderStatus === 'success') {
      toast.success('Order cancellation iniated successfully!');
      return;
    } else if (cancelOrderStatus.orderStatus === 'error') {
      toast.error(
        cancelOrderStatus.error ??
          'Failed to cancel order.Please contact Ravo support center!'
      );
      return;
    }
  }, [cancelOrderStatus]);

  useEffect(() => {
    console.log('Effect triggered :: ', dispatchVehicleStatus);
    if (dispatchVehicleStatus.orderStatus === 'success') {
      toast.success('Vehicle marked as delivered!');
      return;
    } else if (dispatchVehicleStatus.orderStatus === 'error') {
      toast.error(
        dispatchVehicleStatus.error ?? 'Failed to mark vehicle as delivered!'
      );
      return;
    }
  }, [dispatchVehicleStatus]);

  useEffect(() => {
    console.log('Effect triggered :: ', markSoldStatus);
    if (markSoldStatus.orderStatus === 'success') {
      toast.success('Vehicle marked as SOLD!');
      return;
    } else if (markSoldStatus.orderStatus === 'error') {
      toast.error(markSoldStatus.error ?? 'Failed to mark vehicle as SOLD!');
      return;
    }
  }, [markSoldStatus]);

  useEffect(() => {
    console.log('Effect triggered :: ', updateUserOrderStatus);
    if (updateUserOrderStatus.orderStatus === 'success') {
      toast.success('User details updated successfully');
      return;
    } else if (updateUserOrderStatus.orderStatus === 'error') {
      toast.error(updateUserOrderStatus.error ?? 'Failed to update user!!');
      return;
    }
  }, [updateUserOrderStatus]);

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
      currentPage: key !== 'currentPage' ? 1 : value
    }));
  };

  // Filter Configuration
  const filterConfig = [
    {
      key: 'searchId',
      type: 'search',
      label: 'Search',
      placeholder: 'Search By Id',
      defaultValue: ''
    },
    {
      key: 'dateRange',
      type: 'dateRange',
      label: 'Date Range',
      defaultValue: [null, null]
    },
    {
      key: 'dealer',
      type: 'custom',
      label: 'Dealer',
      defaultValue: null
    }
  ];

  const statusOptions = [
    { value: '', label: 'All' },
    { value: 'reserved', label: 'Reserved' },
    { value: 'fully_paid', label: 'Fully Paid' },
    { value: 'cancelled', label: 'Cancelled' }
  ];

  return (
    <React.Fragment>
      <Content>
        <FilterSection
          title="All Orders"
          filters={filters}
          filterConfig={filterConfig}
          statusOptions={statusOptions}
          onFilterChange={handleFilterChange}
          clearFilters={clearFilters}
          customComponents={{
            dealer: DealerFilter
          }}
        />
        <TableComponent
          data={data}
          columns={columns}
          totalCount={totalCount}
          perPage={perPage}
          selectable={false}
          currentPage={filters.currentPage}
          onPageChange={handlePageChange}
          onSortChange={handleSortChange}
          loading={fetchOrdersStatus === 'loading'}
          selectedRows={selected}
          onSelectAll={handleSelectAll}
          onSelectRow={handleSelectRow}
        />

        {activeMenuDialog === menuItems.UPDATE_USER && selectedOrder && (
          <UpdateUserInfo
            order={selectedOrder}
            open={activeMenuDialog === menuItems.UPDATE_USER}
            onClose={handleCloseDialog}
          />
        )}

        {activeMenuDialog === menuItems.TRANSACTION && selectedOrder && (
          <AddTransaction
            order={selectedOrder}
            open={activeMenuDialog === menuItems.TRANSACTION}
            onClose={handleCloseDialog}
          />
        )}
        {activeMenuDialog === menuItems.SOLD && (
          <MarkAsSold
            order={selectedOrder}
            open={activeMenuDialog === menuItems.SOLD}
            onClose={handleCloseDialog}
            onAddTransactionClicked={(e) =>
              handleOpenDialog(menuItems.TRANSACTION, selectedOrder)
            }
          />
        )}
        {activeMenuDialog === menuItems.CANCEL && (
          <CancelOrder
            order={selectedOrder}
            open={activeMenuDialog === menuItems.CANCEL}
            onClose={handleCloseDialog}
          />
        )}
        {activeMenuDialog === menuItems.DISPATCH && (
          <DispatchVehicle
            order={selectedOrder}
            open={activeMenuDialog === menuItems.DISPATCH}
            onClose={handleCloseDialog}
            onAddTransactionClicked={(e) =>
              handleOpenDialog(menuItems.TRANSACTION, selectedOrder)
            }
          />
        )}
      </Content>
    </React.Fragment>
  );
};

export default OrdersListPage;
