import React, { useState, useEffect } from 'react';
import Content from '../../layout/content/Content';
import { Link } from 'react-router-dom';
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Carousal,
  Col,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  Row
} from '../../components/Component';
import { Card } from 'reactstrap';

import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchCarDetails } from '../../redux/features/itemsSlice/itemDetailsSlice';
import CarDetailsInspectionTab from './components/CarDetailsInspectionTab';
import CarDetailsDocumentsTab from './components/CarDetailsDocumentsTab';
import CarDetailsActivitiesTab from './components/CarDetailsActivitiesTab';
import { Box, Grid2, Paper } from '@mui/material';
import { useSelector } from 'react-redux';
import BackButton from '../../components/button/BackButton';
import CarDetailsList from './components/CarDetailsList';
import AllAuctions from '../auctions/AllAuctions';
import { startCase } from 'lodash';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

export default function AuctionCarDetails() {
  const labels = ['Car Details', 'Inspection', 'Documents', 'Activities'];
  const [currentTabSelected, setCurrentTabSelected] = useState(0);
  const [sm, updateSm] = useState(false);

  const [images, setImages] = useState([]);

  const carDetails = useSelector((state) => state.carDetails.data);

  const location = useLocation();

  const searchParams = queryString.parse(location.search);

  // console.log(carDetails);

  const { ItemId } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCarDetails(ItemId));
  }, []);


  useEffect(() => {
    if (carDetails) {
    
      setImages(carDetails.images);
    }
  }, [carDetails]);

  return (
    <React.Fragment>
      <Content>
        <BackButton />

        <Grid2 container spacing={4} classes="card-inner nk-store-statistics">
          <Grid2 item size={8}>
            <Box
              width={'100%'}
              height={'36rem'}
              overflow="clip"
              sx={{ objectPosition: 'center' }}
            >
              <Paper elevation={0} sx={{ height: '36rem' }}>
                {carDetails && <Carousal images={images} id={ItemId} />}
              </Paper>
            </Box>
          </Grid2>
          <Grid2 item size={4}>
            <Paper
              elevation={0}
              sx={{ height: 1, color: '#ffffff', padding: 4 }}
            >
              <BlockHead size="lg">
                <BlockBetween>
                  <BlockHeadContent>
                    <BlockTitle tag="h4">Item Details</BlockTitle>
                    <BlockDes>
                      <p>Basic information</p>
                    </BlockDes>
                  </BlockHeadContent>
                </BlockBetween>
              </BlockHead>

              <ul className="nk-store-statistics">
                <li>
                  <Row>
                    <Col sm="6">
                      <div className="info">
                        <div className="title">ID</div>
                        <div className="count-details">
                          {carDetails?.baseform_id}
                        </div>
                      </div>
                    </Col>
                    <Col sm="6">
                      <div className="info">
                        <div className="title">Owner</div>
                        <div className="count-details">
                          <Link to={`/users/${carDetails?.user_id?.id}`}>
                            {`${startCase(carDetails?.user_id?.full_name)}`}
                          </Link>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </li>

                <CarDetailsList data={carDetails} />
        
              </ul>
            </Paper>
          </Grid2>
          <Grid2 item size={12}>
            <Card className="card-bordered">
              <div className="card-aside-wrap">
                <div
                  className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
                    sm ? 'content-active' : ''
                  }`}
                >
                  <div className="card-inner-group">
                    <div className="card-inner p-0">
                      <ul className="link-list-menu">
                        <li onClick={() => updateSm(false)}>
                          <Link
                            onClick={() => setCurrentTabSelected(0)}
                            className={currentTabSelected === 0 ? 'active' : ''}
                          >
                            <Icon name="bell-fill"></Icon>
                            <span>Inspection</span>
                          </Link>
                        </li>
                        <li onClick={() => updateSm(false)}>
                          <Link
                            onClick={() => setCurrentTabSelected(1)}
                            className={currentTabSelected === 1 ? 'active' : ''}
                          >
                            <Icon name="activity-round-fill"></Icon>
                            <span>Auctions</span>
                          </Link>
                        </li>
                        {carDetails?.transaction?.length > 0 && (
                          <li onClick={() => updateSm(false)}>
                            <Link
                              onClick={() => setCurrentTabSelected(2)}
                              className={
                                currentTabSelected === 2 ? 'active' : ''
                              }
                            >
                              <Icon name="lock-alt-fill"></Icon>
                              <span>Item Transaction</span>
                            </Link>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
                <Box width={1} padding={3}>
                  <div hidden={currentTabSelected !== 0}>
                    <CarDetailsInspectionTab
                      isConciergeBooking={searchParams?.concierge_id}
                    />
                  </div>
                  <div hidden={currentTabSelected !== 1}>
                    <div
                      id="user-auctions"
                      className="mb-5 bg-lighter"
                      style={{
                        backgroundColor: 'light',
                        borderRadius: 4,
                        marginTop: 16,
                        marginBottom: 16
                      }}
                    >
                      {ItemId && <AllAuctions itemId={ItemId} />}
                    </div>
                    {/* <CarDetailsDocumentsTab /> */}
                  </div>
                  <div hidden={currentTabSelected !== 2}>
                    {/* <CarDetailsActivitiesTab /> */}
                    <div
                      id="transaction"
                      className="mb-5 bg-lighter"
                      style={{
                        backgroundColor: 'light',
                        borderRadius: 4,
                        marginTop: 16,
                        marginBottom: 16
                      }}
                    >
                      <Content>
                        <BlockHead size="lg">
                          <BlockBetween>
                            <BlockHeadContent>
                              <BlockTitle tag="h4">Item transfer</BlockTitle>
                            </BlockHeadContent>
                          </BlockBetween>
                        </BlockHead>
                        <Block>
                          <div className="nk-tb-list is-separate is-medium mb-3">
                            <DataTableHead className="nk-tb-item ">
                              <DataTableRow size="lg">
                                <span className="sub-text">From User</span>
                              </DataTableRow>
                              <DataTableRow size="lg">
                                <span className="sub-text">To User</span>
                              </DataTableRow>
                              <DataTableRow size="md">
                                <span className="sub-text">Auction</span>
                              </DataTableRow>
                              <DataTableRow size="md">
                                <span className="sub-text">Date</span>
                              </DataTableRow>
                            </DataTableHead>

                            {carDetails?.transaction?.length > 0
                              ? carDetails?.transaction?.map((item, index) => (
                                  <DataTableItem key={index}>
                                    <DataTableRow>
                                      <span className="tb-sub">
                                        <span>
                                          {startCase(
                                            item?.from_user_id?.full_name
                                          )}
                                        </span>
                                      </span>
                                    </DataTableRow>
                                    <DataTableRow>
                                      <span className="tb-sub">
                                        <span>
                                          {startCase(
                                            item?.to_user_id?.full_name
                                          )}
                                        </span>
                                      </span>
                                    </DataTableRow>
                                    <DataTableRow>
                                      <span className="tb-sub">
                                        <span>
                                          {item?.auction_id?.serial_number ??
                                            ''}
                                        </span>
                                      </span>
                                    </DataTableRow>
                                    <DataTableRow>
                                      <span className="tb-sub">
                                        <span>
                                          {new Date(
                                            item.createdAt
                                          ).toDateString()}
                                        </span>
                                      </span>
                                    </DataTableRow>
                                  </DataTableItem>
                                ))
                              : null}
                          </div>
                        </Block>
                      </Content>
                    </div>
                  </div>
                </Box>
              </div>
            </Card>
          </Grid2>
        </Grid2>
      </Content>
    </React.Fragment>
  );
}
