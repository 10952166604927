import React from 'react';
import { Card } from '@mui/material';
import { TrendingUp, TrendingDown } from '@mui/icons-material';

const  MetricsCard = ({ title, value, change, icon }) => {
  return (
    <Card className="p-3">
      <div className="d-flex justify-content-between">
        <div>
          <h6 className="text-muted mb-1">{title}</h6>
          <h3 className="mb-2">{value}</h3>
          <div className={`text-${change >= 0 ? 'success' : 'danger'} d-flex align-items-center`}>
            {change >= 0 ? <TrendingUp fontSize="small" /> : <TrendingDown fontSize="small" />}
            <span className="ms-1">{Math.abs(change)}%</span>
          </div>
        </div>
        <div className="icon-bg">{icon}</div>
      </div>
    </Card>
  );
};

export default MetricsCard;