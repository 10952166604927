
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { axios } from "../../../utils/axios";

const initialState = {
    data: {},
    loading: false,
    error: null,
}

const settleDealerRemote = createAsyncThunk('settleDealer',(data) => {
    const url = "/admin/paymenttransaction/settle-dealer/";
    return axios.post(url,{reservationId:data.reservationId}).then((response) => {
        console.log("Response: :", response);
        return response.data;
    });
});


const getSettleDealerSlice = createSlice({
    name : "settleDealer",
    initialState,
    reducers: {
          resetStatus: (state) => {
          state.data = {};
          state.error = null;
          state.loading = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(settleDealerRemote.pending, (state) => {
            state.loading = true;
            state.error = null;
            state.data = {};
        });

        builder.addCase(settleDealerRemote.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload?.data;
        });

        builder.addCase(settleDealerRemote.rejected, (state,action) => {
            console.log("Action", action);
              state.loading = false;
              state.data = {};
              state.error = action.error.message;
        });
    }
});

export default getSettleDealerSlice.reducer;
export const { settleDealer } = getSettleDealerSlice.actions;
export { settleDealerRemote};