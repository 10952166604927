// components/dashboard/Dashboard.jsx
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid2, CircularProgress, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { 
  AccountBalance,
  Assessment,
  People,
  TrendingUp 
} from '@mui/icons-material';
import {
  fetchDailyAnalytics,
  fetchAnalyticsByRange,
  fetchAnalyticsSummary
} from '../../redux/features/analyticsSlice/analyticsSlice';
import MetricsCard from './components/MetricsCard';
import RevenueChart from './components/RevenueChart';
import AnalyticsTable from './components/AnalyticsTable';

const Dashboard = () => {
  const dispatch = useDispatch();
  const { dailyData, rangeData, summary, loading } = useSelector(state => state.analytics);
  const [dateRange, setDateRange] = useState([
    new Date(Date.now() - 30 * 24 * 60 * 60 * 1000),
    new Date()
  ]);

  useEffect(() => {
    dispatch(fetchDailyAnalytics());
  }, [dispatch]);

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      dispatch(fetchAnalyticsByRange({
        startDate: dateRange[0].toISOString(),
        endDate: dateRange[1].toISOString()
      }));
      dispatch(fetchAnalyticsSummary({
        startDate: dateRange[0].toISOString(),
        endDate: dateRange[1].toISOString()
      }));
    }
  }, [dateRange, dispatch]);

  if (loading && !dailyData) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
        <CircularProgress />
      </div>
    );
  }

  return (

      <Container maxWidth="xl" sx={{ py: 4 }}>
      {/* Date Range Selector */}
      <Grid2 container spacing={2} sx={{ mb: 4 }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid2 item xs={12} sm={6} md={3}>
          <DatePicker
            label="Start Date"
            value={dateRange[0]}
            onChange={(newValue) => setDateRange([newValue, dateRange[1]])}
            renderInput={(params) => <TextField {...params} fullWidth />}
            inputFormat="dd/MM/yyyy"
          />
        </Grid2>
        <Grid2 item xs={12} sm={6} md={3}>
          <DatePicker
            label="End Date"
            value={dateRange[1]}
            onChange={(newValue) => setDateRange([dateRange[0], newValue])}
            renderInput={(params) => <TextField {...params} fullWidth />}
            inputFormat="dd/MM/yyyy"
          />
        </Grid2>
        </LocalizationProvider>
      </Grid2>

      {/* Metrics Cards */}
      <Grid2 container spacing={3} className="mb-4">
        <Grid2 item size={3}>
          <MetricsCard
            title="Total Deposits"
            value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'AED' })
              .format(summary?.totalDeposits || 0)}
            change={2.5}
            icon={<AccountBalance />}
          />
        </Grid2>
        <Grid2 item size={3}>
          <MetricsCard
            title="Total Fees"
            value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'AED' })
              .format(summary?.totalFees || 0)}
            change={1.8}
            icon={<Assessment />}
          />
        </Grid2>
        <Grid2 item size={3}>
          <MetricsCard
            title="Net Balance"
            value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'AED' })
              .format(summary?.netRevenue || 0)}
            change={3.2}
            icon={<TrendingUp />}
          />
        </Grid2>
        <Grid2 item size={3}>
          <MetricsCard
            title="Total Users"
            value={summary?.totalUsers || 0}
            change={-0.8}
            icon={<People />}
          />
        </Grid2>
        <Grid2 item size={3}>
          <MetricsCard
            title="Total Auctions"
            value={summary?.totalUsers || 0}
            change={-0.8}
            icon={<People />}
          />
        </Grid2>
        <Grid2 item size={3}>
          <MetricsCard
            title="Total Dealers"
            value={summary?.totalUsers || 0}
            change={-0.8}
            icon={<People />}
          />
        </Grid2>
      </Grid2>

      {/* Revenue Chart */}
      <div className="mb-4">
        <RevenueChart data={rangeData} />
      </div>

      {/* Analytics Table */}
      <div className="mb-4">
        <AnalyticsTable data={rangeData} />
      </div>
    </Container>
  );
};

export default Dashboard;