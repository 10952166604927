import React, { useEffect, useState, useCallback } from 'react';
import Content from '../../layout/content/Content';
import Head from '../../layout/head/Head';
import FilterSection from '../../components/table/FilterSection';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllRefunds } from '../../redux/features/usersSlice/refundSlice';
import { Badge } from 'reactstrap';
import { capitalize } from '../../utils/Utils';
import ConfirmRefundModalButton from './components/ConfirmRefundModalButton';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import TableComponent from '../../components/table/TableComponent';

function RefundRequests() {
  const dispatch = useDispatch();
  const { data, loading, totalCount, perPage } = useSelector(
    (state) => state.refunds
  );

  // Combined filters state
  const [filters, setFilters] = useState({
    searchText: '',
    dateRange: [null, null],
    status: '',
    currentPage: 1,
    sortByField: 'createdAt',
    sortByValue: -1
  });

  // Filter configuration
  const filterConfig = [
    {
      key: 'searchText',
      type: 'search',
      label: 'Search',
      placeholder: 'Search ID',
      defaultValue: ''
    },
    {
      key: 'dateRange',
      type: 'dateRange',
      label: 'Date Range',
      defaultValue: [null, null]
    }
  ];

  const statusOptions = [
    { value: "", label: "All" },
    { value: 'initiated', label: "Initiated" },
    { value: 'success', label: "Success" },
    { value: 'failed', label: "Failed" },
    { value: 'cancelled', label: "Cancelled" }
  ];

  // Handle filter changes
  const handleFilterChange = (key, value) => {
    setFilters(prev => ({
      ...prev,
      [key]: value,
      currentPage: key !== 'currentPage' ? 1 : value
    }));
  };

  // Fetch data based on filters
  const fetchData = useCallback(() => {
    const requestData = {
      options: {
        page: filters.currentPage,
        limit: perPage,
        sort: filters.sortByValue === 1 ? filters.sortByField : `-${filters.sortByField}`
      },
      query: {}
    };

    if (filters.status) {
      requestData.query.status = filters.status;
    }

    if (filters.dateRange[0] && filters.dateRange[1]) {
      requestData.query.date = {
        from: filters.dateRange[0].toISOString(),
        to: filters.dateRange[1].toISOString()
      };
    }

    if (filters.searchText.trim()) {
      requestData.query.search = filters.searchText.trim();
    }

    dispatch(fetchAllRefunds(requestData));
  }, [filters, dispatch, perPage]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const getStatusBadgeColor = (status) => {
    switch (status) {
      case 'initiated':
        return 'gray';
      case 'success':
        return 'success';
      case 'cancelled':
        return 'warning';
      case 'failed':
        return 'danger';
      default:
        return 'gray';
    }
  };

  // Handle pagination and sorting
  const handlePageChange = (newPage) =>
    setFilters((prev) => ({ ...prev, currentPage: newPage }));

  const handleSortChange = (field, direction) => {
    setFilters((prev) => ({
      ...prev,
      sortByField: field,
      sortByValue: direction,
      currentPage: 1
    }));
  };

  const clearFilters = () => {
    setFilters({
      searchText: '',
      dateRange: [null, null],
      status: '',
      currentPage: 1,
      sortByField: 'createdAt',
      sortByValue: -1
    });
  };

  // Table columns configuration
  const columns = [
    {
      label: 'ID',
      field: 'refund_id',
      sortable: true,
      render: (item) => item.refund_id
    },
    {
      label: 'User',
      field: 'user',
      sortable: false,
      render: (item) => (
        <div>
          {item.user?.full_name}
          <br />
          {item.user?.phone_number}
        </div>
      )
    },
    {
      label: 'Date',
      field: 'createdAt',
      sortable: true,
      render: (item) => new Date(item.createdAt).toLocaleString()
    },
    {
      label: 'Amount',
      field: 'amount',
      sortable: true,
      render: (item) => item.amount?.toFixed(2)
    },
    {
      label: 'Status',
      field: 'status',
      sortable: true,
      render: (item) => (
        <Badge color={getStatusBadgeColor(item.status)}>
          {capitalize(item.status.toString())}
        </Badge>
      )
    },
    {
      label: 'Type',
      field: 'type',
      sortable: true,
      render: (item) => capitalize(item.type)
    },
    {
      label: 'Order Details',
      field: 'order_id',
      sortable: false,
      render: (item) => (
        <div>
          {item.order_id && (
            <div>
              <Link to={`/orders/${item.order_id?.id}`}>
                {item.order_id.order_id}
              </Link>
              <br />
              {item.order_id.dealer?.company?.name}
              <br />
              {item.order_id.vehicle?.make} {item.order_id.vehicle?.model}
            </div>
          )}
        </div>
      )
    },
    {
      label: 'Actions',
      field: 'actions',
      sortable: false,
      render: (item) => (
        item.status !== 'PROCESSED' && item.status !== 'cancelled' && (
          <ConfirmRefundModalButton refund={item} />
        )
      )
    }
  ];

  return (
    <>
      <Head title="Refunds" />
      <Content>
        <Box sx={{ mb: 3 }}>
          <FilterSection
            title="Refund Requests"
            filters={filters}
            filterConfig={filterConfig}
            statusOptions={statusOptions}
            onFilterChange={handleFilterChange}
            clearFilters={clearFilters}
          />
        </Box>

        <TableComponent
          data={data?.data || []}
          columns={columns}
          totalCount={totalCount}
          perPage={perPage}
          currentPage={filters.currentPage}
          onPageChange={handlePageChange}
          onSortChange={handleSortChange}
          loading={loading}
          selectable={false}
        />
      </Content>
    </>
  );
}

export default RefundRequests;