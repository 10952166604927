import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import {
  Badge,
  ButtonGroup,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import Content from '../../layout/content/Content';
import Head from '../../layout/head/Head';
import FilterSection from '../../components/table/FilterSection';
import TableComponent from '../../components/table/TableComponent';
import { capitalize, startCase } from 'lodash';
import { Icon } from '../../components/Component';
import { Box, Stack } from '@mui/material';
import { TimerText } from '../../components/timer/timer';
import {
  clearAuctions,
  debouncedFetchAuctions,
  fetchAuctions,
  setAllSelected,
  setSelected
} from '../../redux/features/auctionSlice/auctionSlice';
import { fetchVerticalDetails } from '../../redux/features/verticalsSlice/verticalDetailsSlice';
import {
  getAuctionStatus,
  getAuctionStatusBgColor,
  getAuctionStatusDotColor
} from '../../utils/getColor';
import { auctionStatus } from '../../utils/Utils';
import formatDate from '../../utils/dateFormat';
import msToTime from '../../utils/msToTime';

const AuctionsData = () => {
  const dispatch = useDispatch();
  const { verticalName } = useParams();
  const [searchParams] = useSearchParams();

  // States
  const [filters, setFilters] = useState({
    searchText: '',
    dateRange: [null, null],
    status: '',
    type: '',
    currentPage: 1,
    sortByField: 'createdAt',
    sortByValue: -1
  });

  // Selectors
  const { data, totalCount, currentPage, loading, perPage } = useSelector(
    (state) => state.auctions
  );

  // Filter Configuration
  const filterConfig = [
    {
      key: 'searchText',
      type: 'search',
      label: 'Search',
      placeholder: 'Search title',
      defaultValue: ''
    },
    {
      key: 'dateRange',
      type: 'dateRange',
      label: 'Date Range',
      defaultValue: [null, null]
    },
  ];

  // Fetch data based on filters
  const fetchData = useCallback(() => {
    const requestData = {
      query: {
        auction_type:'LIVE'
      },
      options: {
        page: filters.currentPage,
        limit: perPage,
        sort: 'status'
      }
    };

    if (filters.status) {
      requestData.query.status = [filters.status];
    }

    if (filters.type) {
      requestData.query.type = filters.type;
    }

    if (filters.dateRange[0] && filters.dateRange[1]) {
      requestData.query.date = {
        from: filters.dateRange[0].toISOString(),
        to: filters.dateRange[1].toISOString()
      };
    }

    if (filters.searchText.trim()) {
      requestData.query.search = filters.searchText.trim();
      dispatch(debouncedFetchAuctions(requestData));
    } else {
      dispatch(fetchAuctions(requestData));
    }
  }, [filters, dispatch, perPage]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    return () => {
      dispatch(clearAuctions());
    };
  }, [dispatch]);

  // Handlers
  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
      currentPage: key !== 'currentPage' ? 1 : value
    }));
  };

  const handlePageChange = (newPage) =>
    handleFilterChange('currentPage', newPage);

  const handleSortChange = (field, direction) => {
    handleFilterChange('sortByField', field);
    handleFilterChange('sortByValue', direction);
  };

  const clearFilters = () => {
    setFilters({
      searchText: '',
      dateRange: [null, null],
      status: '',
      type: '',
      currentPage: 1,
      sortByField: 'createdAt',
      sortByValue: -1
    });
  };

  const statusFilter = () => {
    return [
      { value: '', label: 'All' },
      ...Object.entries(auctionStatus).map(([key, value]) => ({
        value: value,
        label: capitalize(key)
      }))
    ];
  };

  // Table columns configuration
  const columns = [
    {
      label: 'Title',
      field: 'title',
      sortable: false,
      render: (item) => (
        <Link to={`/auctions/${item.id}`}>
          {item?.baseform?.make} {item?.baseform?.model} {item?.baseform?.year}
        </Link>
      )
    },
    {
      label: 'User',
      field: 'user',
      sortable: false,
      render: (item) => startCase(item?.user?.full_name)
    },
    {
      label: 'Start Time',
      field: 'start_time',
      sortable: true,
      render: (item) => formatDate(new Date(item.start_time))
    },
    {
      label: 'Duration',
      field: 'duration',
      sortable: true,
      render: (item) =>
        item.status === auctionStatus.LIVE ? (
          <TimerText
            startTime={item.start_time}
            duration={item.duration}
            onTimerEnd={() => console.log('Timer ended')}
          />
        ) : (
          msToTime(item.duration)
        )
    },
    {
      label: 'Initial Price (AED)',
      field: 'initial_price',
      sortable: false,
      render: (item) => item.initial_price?.toLocaleString('en-US')
    },
    {
      label: 'Bid Count',
      field: 'bid_count',
      sortable: true,
      render: (item) => item.bid_count
    },
    {
      label: 'Status',
      field: 'status',
      sortable: true,
      render: (item) => (
        <Badge
          className={`badge-dot has-bg ${
            item.status === auctionStatus.LIVE ? 'live' : ''
          }`}
          color={getAuctionStatusBgColor(item.status)}
        >
          {startCase(getAuctionStatus(item.status))}
        </Badge>
      )
    },
    {
      label: 'Actions',
      field: 'actions',
      sortable: false,
      render: (item) => (
        <div className="d-flex justify-content-end">
          <Link
            to={`/auctions/${item.id}`}
            className="btn btn-trigger btn-icon"
          >
            <Icon name="eye" />
          </Link>
        </div>
      )
    }
  ];

  return (
    <>
      <Head title={`${verticalName} Auctions List`} />
      <Content>
        <Box sx={{ mb: 3 }}>
          <FilterSection
            title={`Timed Auctions`}
            filters={filters}
            filterConfig={filterConfig}
            statusOptions={statusFilter()}
            onFilterChange={handleFilterChange}
            clearFilters={clearFilters}
          />
        </Box>

        <TableComponent
          data={data?.data || []}
          columns={columns}
          totalCount={totalCount}
          perPage={perPage}
          currentPage={filters.currentPage}
          onPageChange={handlePageChange}
          onSortChange={handleSortChange}
          loading={loading}
          selectable={true}
          onSelectAll={(checked) =>
            dispatch(setAllSelected({ value: checked }))
          }
          onSelectRow={(id, checked) =>
            dispatch(setSelected({ id, value: checked }))
          }
        />
      </Content>
    </>
  );
};

export default AuctionsData;
