import { Paper } from '@mui/material'
import React, { useEffect } from 'react'
import { Badge, Col, Row } from 'reactstrap'
import { PreviewCard } from '../../../components/Component'
import { useDispatch } from 'react-redux'
import { fetchAllPayments, resetPaymentSlice } from '../../../redux/features/usersSlice/paymentSlice'
import { useSelector } from 'react-redux'
import TableComponent from '../../../components/table/TableComponent'
import { startCase } from 'lodash'
import { Link } from 'react-router-dom'
import Invoice from '../../payments/invoice'
import { getColor } from '../../../utils/getColor'
import { getUserWallet, resetWalletSlice } from '../../../redux/features/usersSlice/walletSlice'

export default function UserWallet({userId}) {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchAllPayments({query:{user_id:userId}}))
        dispatch(getUserWallet(userId))
        return () => {
          dispatch(resetPaymentSlice());
          dispatch(resetWalletSlice())
        }
    },[])
    const {data, totalCount, perPage, loading} = useSelector((state) => state.payments)
    const {wallet} = useSelector((state) => state.userWallet)

    const columns = [
        {
          label: 'ID',
          field: 'id',
          sortable: false,
          render: (item) => <Invoice item={item} />
        },
        {
          label: 'User',
          field: 'user_id',
          sortable: false,
          render: (item) => startCase(item?.user_id?.full_name)
        },
        {
          label: 'Payment For',
          field: 'payment_for',
          sortable: false,
          render: (item) => item?.payment_for
        },
        {
          label: 'Auction',
          field: 'auction_id',
          sortable: false,
          render: (item) => (
            <Link 
              to={`/v/${item?.vertical_id}/auctions/${item?.auction_id?.id}`}
              className="text-truncate d-inline-block"
              style={{ maxWidth: '30ch' }}
            >
              {startCase(item?.item_id?.title)}
            </Link>
          )
        },
        {
          label: 'Amount',
          field: 'amount',
          sortable: true,
          render: (item) => item?.amount?.toFixed(2)
        },
        {
          label: 'Status',
          field: 'status',
          sortable: true,
          render: (item) => (
            <Badge color={getColor(item?.status)}>
              {startCase(item?.status)}
            </Badge>
          )
        },
        {
          label: 'Created At',
          field: 'createdAt',
          sortable: true,
          render: (item) => {
            const dateOptions = {
              weekday: 'short',
              month: 'short',
              day: 'numeric',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit'
            };
            return new Date(item?.createdAt).toLocaleString(undefined, dateOptions);
          }
        }
      ];
  return (
    <Paper elevation={0} sx={{ height: "100%" }}>
      <PreviewCard style={{ height: "100%" }}>
        <div className="dataTables_wrapper dt-bootstrap4 no-footer">
          <Row className="justify-between g-2">
            <Col className="col-7 text-start" sm="4">
              <div className="card-title">
                <h6 className="text mb-2">Wallet</h6>
                <div className="d-flex align-items-center">
                  <span className="text-muted me-2">Available Balance:</span>
                  <h4 className="m-0">AED {wallet?.amount?.toFixed(2) || '0.00'}</h4>
                </div>
              </div>
            </Col>
          </Row>
          <TableComponent
            data={data?.data || []}
            columns={columns}
            totalCount={totalCount}
            perPage={perPage}
            loading={loading}
            selectable={false}
          />
        </div>
      </PreviewCard>
    </Paper>
  )
}