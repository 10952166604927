
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { axios } from "../../../utils/axios";

const initialState = {
    wallet: {},
    loading: false,
    error: null,
}

const getUserWallet = createAsyncThunk('getUserWallet',(data) => {
    const url = `/admin/wallet/user/${data}`;
    return axios.get(url).then((response) => {
        return response.data;
    });
});


const userWalletSlice = createSlice({
    name : "userWallet",
    initialState,
    reducers: {
          resetWalletSlice: (state) => {
          state.wallet = {};
          state.error = null;
          state.loading = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getUserWallet.pending, (state) => {
            state.loading = true;
            state.error = null;
            state.wallet = {};
        });

        builder.addCase(getUserWallet.fulfilled, (state, action) => {
            state.loading = false;
            state.wallet = action.payload?.data;
        });

        builder.addCase(getUserWallet.rejected, (state,action) => {
              state.loading = false;
              state.wallet = {};
              state.error = action.error.message;
        });
    }
});

export default userWalletSlice.reducer;
export const { resetWalletSlice } = userWalletSlice.actions;
export { getUserWallet};